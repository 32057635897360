import React from "react";
import PropTypes from "prop-types";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

// Register the font
Font.register({
  family: "Helvetica",
  src: "path/to/Helvetica-Bold.ttf", // Update this path to your bold font file
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
    fontSize: 7,
  },
  headerSection: {
    marginBottom: 20,
    fontSize: 7,
  },
  headerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    fontSize: "8px",
  },
  boldText: {
    fontWeight: "bold",
    fontSize: "8px",
    fontFamily: "Helvetica-Bold", // Use built-in bold font
  },
  title: {
    textAlign: "center",
    fontSize: 12, // Reduced title font size
    fontWeight: "bold",
  },
  table: {
    display: "flex",
    width: "auto", // Adjusts table to the page width
    marginTop: 20,
    borderStyle: "solid",
    borderColor: "#ddd",
    borderWidth: 1,
    marginBottom: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    backgroundColor: "#4667c8",
    color: "white",
    borderStyle: "solid",
    borderColor: "#ddd",
    borderWidth: 1,
    padding: 5,
    textAlign: "center",
    fontSize: 9,
  },
  tableCol: {
    borderStyle: "solid",
    borderColor: "#ddd",
    borderWidth: 1,
    padding: 5,
  },
  tableCell: {
    textAlign: "left",
    fontSize: 7, // Data cell font size
    height: "10px",
  },
  header: {
    fontSize: 7,
    marginBottom: 10,
  },
});

const columnWidths = [
  50, 70, 120, 40, 30, 50, 50, 60, 60, 50, 60, 40, 40, 50, 50,
]; // Example widths for 13 columns

const MyDocument = ({
  data,
  fromDate,
  toDate,
  today,
  currentTime,
}) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      {/* Header Section */}
      <View fixed style={styles.headerSection}>
        <View style={styles.headerRow}>
          {/* Store Text */}
          <Text>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "8px",
                fontFamily: "Helvetica-Bold",
              }}
            >
              Store:
            </Text>

            <Text> 285 PRICE CHOPPER 285</Text>
          </Text>

          {/* Title split into two rows */}
          <View>
            <Text
              style={{
                textAlign: "center",
                fontSize: 12,
                fontFamily: "Helvetica-Bold",
              }}
            >
              Grocery Order Tracking
            </Text>
            <Text
              style={{
                marginLeft: "30px",
                fontSize: 12,
                fontFamily: "Helvetica-Bold", // Use the registered font family
                marginTop: "5px",
              }}
            >
              Out of Stock
            </Text>
          </View>

          {/* Remote Report Text */}
          <Text style={{ fontSize: "7px" }}>Remote Report 0029</Text>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 3,
            fontWeight: "bold",
            fontSize: "8px",
            marginTop: "-15px",
            fontFamily: "Helvetica", // Use the registered font family
          }}
        >
          <Text>
            <Text
              style={{
                fontWeight: "bold",
                fontSize: "8px",
                fontFamily: "Helvetica-Bold",
              }}
            >
              Vendor:
            </Text>
            <Text> AWG KC</Text>
          </Text>

          <Text style={{ fontSize: "7px", marginTop: "-10px" }}>
            <Text>{today}</Text>
          </Text>
        </View>

        <View style={styles.headerRow}>
          <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
            OOS Date:{" "}
            <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
              {fromDate}
            </Text>
          </Text>
          <Text style={{ fontSize: "7px", marginTop: "-10px" }}>
            {currentTime}
          </Text>
        </View>

        <View style={styles.headerRow}>
          <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
            Invoice Date:{" "}
            <Text style={{ fontFamily: "Helvetica-Bold", fontWeight: "bold" }}>
              {toDate}
            </Text>
          </Text>
          <Text
            style={{
              fontSize: "9px",
              fontWeight: "bold",
              fontFamily: "Helvetica-Bold", // Use built-in bold font
              marginTop: "-15px",
            }}
            render={({ pageNumber, totalPages }) => `Page # ${pageNumber}`}
            fixed
          />
        </View>
      </View>

      {/* Table Header */}
      <View style={styles.table} wrap>
        <View style={styles.tableRow} fixed>
          {data.length > 0 &&
            Object.keys(data[0]).map((colName, colIndex) => (
              <View
                key={colIndex}
                style={[
                  styles.tableColHeader,
                  { width: columnWidths[colIndex] },
                ]}
              >
                <Text>{colName.replace(/_/g, " ")}</Text>
              </View>
            ))}
        </View>
        {/* Table Rows */}
        {data.map((item, rowIndex) => (
          <View key={rowIndex} style={styles.tableRow}>
            {Object.values(item).map((value, colIndex) => (
              <View
                key={colIndex}
                style={[styles.tableCol, { width: columnWidths[colIndex] }]}
              >
                <Text style={styles.tableCell}>
                  {typeof value === "string" || typeof value === "number"
                    ? value
                    : JSON.stringify(value)}
                </Text>
              </View>
            ))}
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

MyDocument.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  fromDate: PropTypes.string.isRequired, // Add this line
  toDate: PropTypes.string.isRequired, // Add this line
  today: PropTypes.string.isRequired, // Add this line
  currentTime: PropTypes.string.isRequired, // Add this line
};

export default MyDocument;
