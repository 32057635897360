import React from "react";
import { useEffect, useState } from "react";
import Constants from "../../.././utils/constants";
import { Button, DatePicker, Dropdown } from "rsuite";
import { Icon } from "@iconify/react";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";
import TableComponent from "../OrderOnHold/TableComponent";
import { OrderListConstants } from "./OrderListConstants";

const OrdersOnHold = (props: any) => {
  const { userContext, handleSpinner } = props;
  const [userStore, setUserStore] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [allOrderData, setAllOrderData] = useState([]);
  const [allOrderDataFiltered, setAllOrederDataFiltered] = useState([]);
  const [rowData, setRowData] = useState([]);

  // Get today's date and subtract 3 days
  const getThreeDaysOldDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 3); // Subtract 3 days from the current date
    return today;
  };

  const [fromDate, setFromDate] = useState(getThreeDaysOldDate());

  // Function to format date to MM/DD/YYYY
  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to get the correct month
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  // Function to make API call
  const fetchOrdersOnHold = async (selectedDate) => {
    handleSpinner && handleSpinner(true);
    const formattedDate = formatDate(selectedDate); // Format the date to MM/DD/YYYY
    const apiUrl = `https://api.gotsystems.net/api/MainScreen/GetOrdersOnHold?date=${formattedDate}`;

    try {
      const response = await fetch(apiUrl);
      if (response.ok) {
        const data = await response.json();
        setRowData(data);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("API error:", error);
    }
    handleSpinner && handleSpinner(false);
  };

  useEffect(() => {
    fetchOrdersOnHold(fromDate);
  }, [fromDate]);

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };

  return (
    <div>
      {/* <div className="scrollable-div"> */}
      <div className="OrderInvoice mb-3">
        <div>
          <DatePicker
            label="Date:"
            oneTap
            value={fromDate}
            onChange={(value) => {
              setFromDate(value); // Update the selected date in the state
              fetchOrdersOnHold(value); // Make API call when date changes
            }}
            cleanable={false}
            style={{ width: "260px", marginLeft: "10px" }}
            menuStyle={{ width: "260px" }}
          />
        </div>
        <div className="mt-3 last-div">
          <div className="d-flex justify-content-end">
            {/* <div
              className="mx-2 filter"
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <Icon icon="material-symbols:filter-alt-outline-sharp" />
              Filters
            </div> */}
            <Dropdown
              renderToggle={(_props, _ref) => (
                <div className="filter" ref={_ref} {..._props}>
                  <Icon icon="pajamas:export" /> Export
                </div>
              )}
              className="ms-2 me-0 button-fixed"
              appearance="link"
              placement="bottomEnd"
            >
              <Dropdown.Item
                icon={<FileDownloadIcon />}
                onClick={() => {
                  exportToExcel(
                    allOrderDataFiltered,
                    `View Confirmations ${userStore}`
                  );
                }}
              >
                Export to Excel
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </div>

      <TableComponent
        userContext={userContext}
        isFromModal={false}
        isTotalRowRequired={true}
        tableDetails={OrderListConstants} // Updated constant
        reportList={rowData}
        //reportList={allOrderDataFiltered ? allOrderDataFiltered : allOrderData}
      />

      {/* </div> */}
    </div>
  );
};

export default OrdersOnHold;
