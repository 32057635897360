import { useEffect, useState, useMemo, useRef } from "react";
import {
  Modal,
  Tabs,
  Dropdown,
  Table,
  Button,
  DatePicker,
  SelectPicker,
  Input,
} from "rsuite";
import { useNavigate, useLocation } from "react-router-dom";
import SyncIcon from "@mui/icons-material/Sync";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as ReportService from "../../../services/reportServices";
import * as SettingsServices from "../../../services/settingsServices";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";
import Constants from "../../.././utils/constants";
import TableComponent1 from "../../common/tableComponent1";
import TableComponent from "../../common/tableComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import { ReportOOSConstants } from "./ReportOutOfStockConstants";
import * as UMSService from "../../../services/umsService";
import * as AuthService from "../../../services/authService";
import { toast } from "react-toastify";
import { addDays } from "date-fns";
import { connect } from "http2";
import { SaveOutOfStock } from "services/orderService";
import Tooltip from "@mui/material/Tooltip";
import xlsxIcon from "../../../assets/images/xlsxIcon.png";
import pdfIcon from "../../../assets/images/pdfIcon.png";
import PDFDocument from "../../common/PDFDocument";
import { pdf } from "@react-pdf/renderer";

const ReportOutOfStockComp = (props: any) => {
  const allRowDataRef = useRef();
  const updatedRowDataRef = useRef<Set<any>>(new Set());

  const [allVendorName, setAllVendorName] = useState([]);
  const [vendorID, setVendorID] = useState(allVendorName[1]);
  const options = [
    { label: "All", value: "All" },
    { label: "Invoiced", value: "Invoiced" },
    { label: "Reasons", value: "Reasons" },
    { label: "Not Ordered", value: "NotOrdered" },
    { label: "Order Extra", value: "OrderExtra" },
    { label: "Not Sent", value: "NotSent" },
  ];

  const { Cell } = Table;
  const [allOOSData, setAllOOSData] = useState([]);
  const [invoicedData, setInvoicedData] = useState([]);
  const [reasonsData, setReasonsData] = useState([]);
  const [notOrderedData, setNotOrderedData] = useState([]);
  const [orderExtraData, setOrderExtraData] = useState([]);
  const [notSentData, setNotSentData] = useState([]);
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));
  // const [fromDateCreate, setFromDateCreate] = useState<Date>(
  //   addDays(new Date(), -1)
  // );
  // const [toDateCreate, setToDateCreate] = useState<Date>(
  //   addDays(new Date(), -1)
  // );
  const [deliveryDate, setDeliveryDate] = useState(null);
  // const [creditsDate, setCreditsDate] = useState(
  //   moment(toDate).format("MM-DD-YYYY")
  // );

  // const [toDate, setToDate] = useState<Date>(new Date());

  const [storeName, setStoreName] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [selectedID, setSelectedID] = useState(null);
  const [storeNameHeader, setStoreNameHeader] = useState([]);
  const [storeID, setStoreID] = useState([]);
  const [userStore, setUserStore] = useState(storeID[0]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [isTableData, setIsTableData] = useState(false);
  const [confirmSearch, setConfirmSearch] = useState(false);
  const [selectedReportType, setSelectedReportType] = useState("All");
  const [tabsActiveKey, setTabsActiveKey] = useState("All");
  const [modalConfirmResolve, setModalConfirmResolve] = useState(null);
  const [isUserConfirmed, setIsUserConfirmed] = useState(false);
  const [UpdatedArr, setUpdatedArray] = useState([]);
  //const [isUpdatedRef, setIsUpdatedRef] = useState(false);
  let isUpdatedRef = false;
  const navigate = useNavigate(); // For programmatic navigation
  const location = useLocation(); // To track current route
  const [nextLocation, setNextLocation] = useState(null); // Store the next location
  const [isNavigating, setIsNavigating] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);

  const [selectedReportConstantName, setSelectedReporConstantName] =
    useState(null);

  ////////////////////
  interface ReportData {
    selectedReportConstantName: any; // Replace 'any' with the correct type if known
    selectedReportTableName: any; // Replace 'any' with the correct type if known
  }

  // Initial state
  const initialReportData: ReportData = {
    selectedReportConstantName: null,
    selectedReportTableName: [], // Adjust based on your initial data structure
  };
  const [reportData, setReportData] = useState<ReportData>(initialReportData);
  const [refreshKey, setRefreshKey] = useState<number>(0);

  // Function to handle table refresh
  const handleRefreshTable = () => {
    quickOrder("");
    updatedRowDataRef.current = new Set();

    getReportData(storeID);

    // Trigger re-render
    setRefreshKey((prevKey) => prevKey + 1);
  };

  ////////////////////

  const [selectedReportTableName, setSelectedReporTableName] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [detailRowData, setDetailRowData] = useState([]);
  const [data, setData] = useState([]);

  const [isDisplay, setIsDisplay] = useState(false);
  const [isActiveMessage, setIsActiveMessage] = useState(false);

  const [activeCreateOrder, setActiveCreateOrder] = useState([]);
  const [downloadCreateOrder, setDownloadCreateOrder] = useState([]);
  const { userContext = {} } = props;
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpenCreate(false);
    setOpen(false);
  };
  const handleDescChange = (value) => {
    setSelectedDesc(value);
    const selectedStore = storeData.find((item) => item.desc === value);
    if (selectedStore) {
      setSelectedID(selectedStore.id); // Set corresponding ID in the input box
    }
  };
  const [openCreate, setOpenCreate] = useState(false);
  const handleCloseCreate = () => {
    setOpenCreate(false);
    setActiveCreateOrder([]);
  };
  const handleCloseMessagePopUp = () => {
    setIsActiveMessage(false);
  };
  const handleCloseMessage = () => {
    setIsDisplay(false);
    setDownloadCreateOrder([]);
  };

  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      !userContext.store &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    } else if (userContext && userContext.store) {
      getVendorStoreName(userContext.store);
    }
  }, [groupTypeID, userContext]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);

  const awgOrGot = [
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ];

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext } = props;
    const { user_ID, store } = userContext;
    var storesArray = [];
    const uniqueVendor = new Set();
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          const storeArray = result.records.map((item) => ({
            id: item.store_ID,
            desc: item.store_Name,
          }));

          const uniquestores = [
            ...new Map(storeArray.map((item) => [item.id, item])).values(),
          ];

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
          setStoreData(uniquestores);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          const storeArray = result.records.map((item) => ({
            id: item.store_ID,
            desc: item.store_Name,
          }));

          const uniquestores = [
            ...new Map(storeArray.map((item) => [item.id, item])).values(),
          ];

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
          setStoreData(uniquestores);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);

  useEffect(() => {
    if (userContext && !userContext.store) {
      SettingsServices.getVendorStoreName(userStore, "Store")
        .then((result) => {
          const uniqueVendor = new Set();
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );

          uniqueVendorData.sort((a, b) => {
            const Store_A = a.vendor_Name;
            const Store_B = b.vendor_Name;

            if (Store_A < Store_B || Store_A.includes("AWG KC")) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {});
    }
  }, [userStore, userContext]);
  //#region Get Group and Store by Types

  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });

          const storeArray = result.map((item) => ({
            id: item.groupStore_uid,
            desc: item.groupStore_desc,
          }));
          if (storeArray.length > 0) {
            setSelectedDesc(storeArray[0].desc);
            setSelectedID(storeArray[0].id);
          }

          setStoreData(storeArray);
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  // useEffect(() => {
  //   debugger;
  //   const handleBeforeUnload = (event) => {
  //     alert("alert");
  //     if (isUpdatedRef) {
  //       const confirmationMessage =
  //         "You have unsaved changes. Are you sure you want to leave?";
  //       event.preventDefault();
  //       event.returnValue = confirmationMessage; // Standard for most browsers
  //       return confirmationMessage; // For older browsers
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [isUpdatedRef]);
  const getReportData = async (storeID) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;

    if (isUpdatedRef) {
      await waitForUserConfirmation(); // This will pause the execution here
    }

    updatedRowDataRef.current = new Set();

    const changeZeroToString = (array) => {
      for (var i = 0; i < array.length; i++) {
        var item = array[i];
        for (var key in item) {
          if (item[key] === 0) {
            item[key] = "";
          }
        }
      }
    };

    if (store !== 0) {
      handleSpinner && handleSpinner(true);
      ReportService.getOutOfStock(
        vendorID,
        storeID,
        moment(fromDate).format("MM-DD-YYYY"),
        moment(toDate).format("MM-DD-YYYY")
      )
        .then((result) => {
          ReportOOSConstants["GeneralConstants"].map((item) => {
            if (item.addDate && item.addDate === true) {
              item.Header = `${item.Header} ${moment(fromDate).format(
                "MM-DD"
              )}`;
            }
          });
          setAllOOSData(result.allRecords);
          setData(result.allRecords);
          setSelectedReporTableName(result.allRecords);
          setInvoicedData(result.invoicedRecords);
          setReasonsData(result.reasonsRecords);
          setNotOrderedData(result.notOrderedRecords);
          setOrderExtraData(result.orderExtraRecords);
          setNotSentData(result.notSentRecords);
          result && setSelectedReportType("All");
          //setIsUpdatedRef(false);
          isUpdatedRef = false;
          if (result.allRecords.length > 0) {
            setIsTableData(true);
          }
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      handleSpinner && handleSpinner(true);
      ReportService.getOutOfStock(
        vendorID,
        storeID,
        moment(fromDate).format("MM-DD-YYYY"),
        moment(toDate).format("MM-DD-YYYY")
      )
        .then((result) => {
          changeZeroToString(result.allRecords);
          changeZeroToString(result.invoicedRecords);
          changeZeroToString(result.reasonsRecords);
          changeZeroToString(result.notOrderedRecords);
          changeZeroToString(result.orderExtraRecords);
          changeZeroToString(result.notSentRecords);
          setAllOOSData(result.allRecords);
          setData(result.allRecords);
          setInvoicedData(result.invoicedRecords);
          setReasonsData(result.reasonsRecords);
          setNotOrderedData(result.notOrderedRecords);
          setOrderExtraData(result.orderExtraRecords);
          setNotSentData(result.notSentRecords);
          setTabsActiveKey("All");
          debugger;
          if (result.allRecords.length > 0) {
            setIsTableData(true);
          }
          //setIsUpdatedRef(false);
          isUpdatedRef = false;
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);

          //setIsUpdatedRef(false);
          isUpdatedRef = false;
        });
    }
  };

  const waitForUserConfirmation = () => {
    debugger;
    return new Promise((resolve) => {
      setConfirmSearch(true); // Open the modal
      setModalConfirmResolve(() => resolve); // Store the resolve function to call later
    });
  };

  const handleModalSearchClick = () => {
    debugger;
    if (modalConfirmResolve) {
      updatedRowDataRef.current = new Set();
      modalConfirmResolve(true); // Resolve the promise
      setConfirmSearch(false); // Close the modal
    }
  };

  useEffect(() => {
    debugger;
    const handleBeforeUnload = (event) => {
      /* event.preventDefault();
      event.returnValue = "";  */ // Standard way to trigger confirmation dialog on refresh/close
      waitForUserConfirmation();
    };

    // const handlePopState = () => {
    //   waitForUserConfirmation();
    // };

    // if (isUpdatedRef) {
    //   waitForUserConfirmation(); // This will pause execution here if isUpdatedRef is true
    // }

    // window.addEventListener("beforeunload", handleBeforeUnload); // Catch refresh/close
    // window.addEventListener("popstate", handlePopState); // Catch navigation (browser back/forward)

    // Cleanup the listeners when the component unmounts
    return () => {
      const cleanupAsync = async () => {
        await waitForUserConfirmation();
      };

      if (isUpdatedRef) {
        cleanupAsync();
      }
      console.log("Cleanup started");
    };
  }, []);

  const getHeader = (value) => {
    ReportOOSConstants[value].map((item) => {
      if (item.DataKey.includes("user_Ord_Adj_Qty")) {
        item.Header = `Order ${moment(deliveryDate).format("MM/DD")}`;
      }
      if (item.DataKey.includes("qty_Loaded")) {
        item.Header = `Ord on ${moment(toDate).format("MM/DD")}`;
      }

      if (item.DataKey.includes("sent_ORD_Qty")) {
        item.Header = `Sent on ${moment(toDate).format("MM/DD")}`;
      }

      if (item.DataKey.includes("inv_Qty")) {
        item.Header = `Inv on ${moment(toDate).format("MM/DD")}`;
      }

      if (item.DataKey.includes("qty_Updated_1")) {
        item.Header = `Ord on ${moment(addDays(fromDate, 0)).format("MM/DD")}`;
      }
      if (item.DataKey.includes("qty_Updated_2")) {
        item.Header = `Ord on ${moment(addDays(fromDate, 1)).format("MM/DD")}`;
      }
      if (item.DataKey.includes("qty_Updated_3")) {
        item.Header = `Ord on ${moment(addDays(fromDate, 2)).format("MM/DD")}`;
      }
      if (item.DataKey.includes("qty_Updated_4")) {
        item.Header = `Ord on ${moment(addDays(fromDate, 3)).format("MM/DD")}`;
      }
    });
    return ReportOOSConstants[value];
  };

  useEffect(() => {
    getDeliverySchedule(
      vendorID,
      userContext && userContext.store ? userContext.store : userStore
    );
  }, [userContext, vendorID]);

  const getDeliverySchedule = (vendorID, storeID) => {
    ReportService.getOutOfStockDeliverySchedule(vendorID, storeID)
      .then((result) => {
        result && setDeliveryDate(result.allRecords[0].closest_Delivery_Day);
      })
      .catch((err) => {
        console.error("Error: Get Orders - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {});
  };
  //   const modifyReportData = (data) => {
  //     let modifiedData = data.map((item) => {
  //       item["dateTime_In"] =
  //         item["dateTime_In"] && moment(item["dateTime_In"]).format("MM-DD-YYYY");
  //       item["invoice_Date"] =
  //         item["invoice_Date"] &&
  //         moment(item["invoice_Date"]).format("MM-DD-YYYY");
  //       item["invoice_Date_Next"] =
  //         item["invoice_Date_Next"] &&
  //         moment(item["invoice_Date_Next"]).format("MM-DD-YYYY");
  //       item["delivery_Date"] =
  //         item["delivery_Date"] &&
  //         moment(item["delivery_Date"]).format("MM-DD-YYYY");

  //       item["rec_Date"] =
  //         item["rec_Date"] && moment(item["rec_Date"]).format("MM-DD-YYYY");

  //       return item;
  //     });
  //     return modifiedData;
  //   };

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion

  //#region Filters Drawer

  //#endregion

  useEffect(() => {
    if (userContext && userContext.store && userContext.store !== 0) {
      getStoreName(userContext.store);
    }
  }, [userContext]);

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const onOptionChangeVendor = (event) => {
    const VendorAndStore = event.toString();
    setVendorID(VendorAndStore);
    //setShowAllData(true);
  };
  useEffect(() => {
    selectedData(selectedReportType);
  }, [selectedReportType, userStore, allOOSData]);

  useEffect(() => {
    setSelectedReporConstantName(getHeader("GeneralConstants"));
  }, [fromDate, toDate]);

  const selectedData = (selectedTab) => {
    if (!selectedTab) {
      setSelectedReporConstantName(getHeader("GeneralConstants"));
      setTabsActiveKey("All");
      setSelectedReporTableName(allOOSData);
      // setIsTableData(true);
    } else {
      switch (selectedTab) {
        case "All":
          setSelectedReporTableName(allOOSData);
          setSelectedReporConstantName(getHeader("GeneralConstants"));
          break;
        case "Invoiced":
          const invoicedRecords = allOOSData.filter(
            (item) => item.inv_Qty !== "" && Number(item.inv_Qty) !== 0
          );
          setSelectedReporTableName(invoicedRecords);
          setSelectedReporConstantName(getHeader("GeneralConstants"));
          break;
        case "Reasons":
          const reasonsRecords = allOOSData.filter(
            (item) => item.reason_filter !== "" && item.reason_filter === 1
          );
          setSelectedReporTableName(reasonsRecords);
          setSelectedReporConstantName(getHeader("ReasonConstants"));
          break;
        case "NotOrdered":
          const notOrderedRecords = allOOSData.filter(
            (item) =>
              (item.qty_Updated === "" || Number(item.qty_Updated) === 0) &&
              (item.user_Ord_Adj_Qty === "" ||
                Number(item.user_Ord_Adj_Qty) === 0)
          );
          setSelectedReporTableName(notOrderedRecords);
          setSelectedReporConstantName(getHeader("GeneralConstants"));
          break;
        case "OrderExtra":
          const orderExtraRecords = allOOSData.filter(
            (item) => item.ooS_99 !== "" && Number(item.ooS_99) !== 0
          );
          setSelectedReporTableName(orderExtraRecords);
          setSelectedReporConstantName(getHeader("GeneralConstants"));
          break;
        case "NotSent":
          const notSentRecords = allOOSData.filter(
            (item) =>
              item.sent_ORD_Qty === "" || Number(item.sent_ORD_Qty) === 0
          );
          setSelectedReporTableName(notSentRecords);
          setSelectedReporConstantName(getHeader("GeneralConstants"));
          break;
      }
    }
  };

  const getDetailRecord = (data) => {
    setOpenCreate(false);
    const changeZeroToString = (array) => {
      for (var i = 0; i < array.length; i++) {
        var item = array[i];

        for (var key in item) {
          if (item[key] === 0 || item[key] === 0.0) {
            item[key] = "";
          }
        }
      }
    };
    setDetailRowData(data);
    data &&
      ReportService.getOutOfStockDetail(
        vendorID,
        userStore,
        moment(fromDate).format("MM-DD-YYYY"),
        data.department_Store_ID,
        data.item_ID,
        data.upc.toString(),
        data.pack1,
        moment(toDate).format("MM-DD-YYYY")

        // asasdasdadas
        // 70038000,
        // 9999,
        // moment(fromDate).format("MM-DD-YYYY"),
        // 3200,
        // 500081,
        // "7003861275",
        // 6,
        // moment(toDate).format("MM-DD-YYYY")
        // testtt
        // 70038000,
        // 9999,
        // moment(fromDate).format("MM-DD-YYYY"),
        // data.department_Store_ID,
        // data.item_ID,
        // data.upc.toString(),
        // data.pack1,
        // moment(toDate).format("MM-DD-YYYY")
      )
        .then((result) => {
          result.allRecords.map(
            (item) =>
              (item.delivery_Date = moment(item.delivery_Date).format(
                "MM-DD-YYYY"
              ))
          );

          changeZeroToString(result.allRecords);
          setDetailData(result.allRecords);
          result && setOpen(true);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {});
  };
  const getCreateData = (value1) => {
    // Create Order Side
    if (value1 && Number(value1.user_Ord_Adj_Qty) !== 0) {
      const newValue = {
        Department_Store_ID: value1.department_Store_ID,
        Store_ID: userStore,
        // Store_ID: 9999,
        Vendor_ID: vendorID,
        // Vendor_ID: 70038000,
        User_ID: userContext && userContext.user_ID,
        UPC: value1.upc ? value1.upc : 0,
        Item_ID: value1.item_ID ? value1.item_ID : 0,
        Status: "0",
        Quantity: Number(value1.user_Ord_Adj_Qty),
        // Delivery_Date: moment(fromDateCreate).format("YYYY-MM-DD"), //from Date
        // Delivery_Date: moment(fromDate).format("YYYY-MM-DD"), //from Date
        Delivery_Date: moment(deliveryDate).format("YYYY-MM-DD"), //from Date
        Update_Date: addDays(new Date(), 0),
        Record_Type: 2,
        Module_ID: 24,
      };
      ReportService.InsertOutofStockOrder(Array(newValue))
        .then((result) => {
          // toast.success(
          //   `${Number(value1.user_Ord_Adj_Qty)} Orders Created Successfully`
          // );
        })
        .catch((err) => {
          // toast.error("Error Loading Orders");
          toast.error("Error Create Orders look UPC or Item ID column");
        })
        .finally(() => {});
    }
    // Create Credits Side
    if (
      value1 &&
      Number(value1.user_Credit_Adj_Qty) !== 0 &&
      value1.credit_Qty !== ""
    ) {
      const newValue = {
        Department_Store_ID: value1.department_Store_ID,
        Store_ID: userStore,
        //Store_ID: 9999,
        Vendor_ID: vendorID,
        // Vendor_ID: 70038000,
        User_ID: userContext && userContext.user_ID,
        UPC: value1.upc,
        Item_ID: value1.item_ID,
        Status: "0",
        Quantity: Number(value1.user_Credit_Adj_Qty),
        // Delivery_Date: moment(toDateCreate).format("YYYY-MM-DD"), //invoice Date
        Delivery_Date: moment(toDate).format("YYYY-MM-DD"), //invoice Date
        Update_Date: addDays(new Date(), 0),
        Record_Type: 6,
        Module_ID: 24,
      };
      ReportService.InsertOutofStockOrder(Array(newValue))
        .then((result) => {
          // toast.success(
          //   `${Number(value1.user_Credit_Adj_Qty)} Credits Created Successfully`
          // );
        })
        .catch((err) => {
          toast.error("Error Create Credits look UPC or Item ID column");
        })
        .finally(() => {});
    }
  };

  const handleSave = async () => {
    console.log("UpdatedArr", UpdatedArr);
    const arrayFromSet = Array.from(updatedRowDataRef.current);
    if (arrayFromSet.length === 0) {
      toast.error("Please select at least one item");
      return;
    }

    const data = [];
    arrayFromSet.map((item) => {
      const userOrdAdjQty = parseInt(item.user_Ord_Adj_Qty);
      const creditQty = parseInt(item.credit_Qty);

      if (!isNaN(creditQty) && creditQty > 0) {
        data.push({
          Store_ID: userStore,
          Vendor_ID: parseInt(vendorID),
          Department_Store_ID: item.department_Store_ID,
          dDelivery_Date: toDate.toISOString(),
          UPC: item.upc.toString(),
          Item_ID: item.item_ID,
          Quantity: creditQty,
          Status: 0,
          UserID: userContext?.user_ID,
          Record_Type: 6,
          Module_ID: 24,
        });
      }

      if (!isNaN(userOrdAdjQty) && userOrdAdjQty > 0) {
        data.push({
          Store_ID: userStore,
          Vendor_ID: parseInt(vendorID),
          Department_Store_ID: item.department_Store_ID,
          dDelivery_Date: fromDate.toISOString(),
          UPC: item.upc.toString(),
          Item_ID: item.item_ID,
          Quantity: userOrdAdjQty,
          Status: 0,
          UserID: userContext?.user_ID,
          Record_Type: 2,
          Module_ID: 24,
        });
      }
    });

    try {
      const result = await SaveOutOfStock(data);
      if (result === 1) {
        quickOrder("");
        updatedRowDataRef.current = new Set();
        toast.success("Saved Successfully!");
        //setIsUpdatedRef(false);
        isUpdatedRef = false;
      }
      setIsRefreshModalOpen(false);
    } catch (error) {
      console.error("Error while saving:", error);
    }
  };

  const EditableCell = ({
    rowData,
    dataKey,
    onChange,
    selectedReportType,
    allRowDataRef,
    ...props
  }) => {
    allRowDataRef.current = rowData;
    if (
      rowData.user_Ord_Adj_Qty.length > 0 ||
      rowData.credit_Qty.length > 0 ||
      rowData.user_Credit_Adj_Qty.length > 0
    ) {
      console.log("is updated ref: ", isUpdatedRef);
      const updatedSet = new Set(updatedRowDataRef.current); // Create a new Set from the current data
      updatedSet.add(rowData);
      //setIsUpdatedRef(true);
      isUpdatedRef = true;
      updatedRowDataRef.current = updatedSet;
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [isBlockWarningModalOpen, setIsBlockWarningModalOpen] =
      useState(false);
    const [pendingValue, setPendingValue] = useState(null);
    const [modalValue, setModalValue] = useState("");
    const [previousValue, setPreviousValue] = useState(rowData[dataKey]);
    const [currentValue, setCurrentValue] = useState(rowData[dataKey]); // Track the current value
    const [isValueChanged, setIsValueChanged] = useState(false);

    useEffect(() => {
      setIsValueChanged(rowData[dataKey] !== "");
    }, [rowData, dataKey]);

    const editing =
      rowData["credit_Qty"] === "" && dataKey === "user_Credit_Adj_Qty"
        ? false
        : selectedReportType === "All"
        ? rowData["credit_Qty"] === "" && dataKey === "user_Credit_Adj_Qty"
          ? true
          : dataKey === "user_Ord_Adj_Qty"
          ? true
          : false
        : true;

    const handleChange = (event) => {
      const value = event.target.value;
      const numericValue = Number(value);

      // Check if the item is blocked
      if (rowData.isBlocked) {
        setIsBlockWarningModalOpen(true);
        return;
      }

      if (numericValue < 0) {
        setIsModalOpen(true); // Show modal if the value is negative
        return;
      }

      if (numericValue > 2) {
        setPendingValue(value);
        setModalValue(value); // Initialize modal input with the current value
        setIsConfirmModalOpen(true); // Show confirmation modal if the value is greater than 2
        return;
      }

      setCurrentValue(value); // Update the current value state
      setIsValueChanged(value !== rowData[dataKey]); // Mark as changed

      // Update the central data reference (allRowDataRef)
      const updatedRow = { ...rowData, [dataKey]: value };
      const updatedData = allRowDataRef.current.map((row) =>
        row.uid === rowData.uid ? updatedRow : row
      );
      allRowDataRef.current = updatedData;
    };

    const handleBlur = () => {
      if (currentValue !== previousValue) {
        onChange && onChange(rowData.uid, dataKey, currentValue); // Trigger the change
        setPreviousValue(currentValue); // Update the previous value
      }
    };

    const handleBlockWarningConfirm = () => {
      setIsBlockWarningModalOpen(false);
      setCurrentValue(previousValue); // Reset to the previous value
    };

    const handleConfirm = () => {
      if (modalValue !== null) {
        setPreviousValue(modalValue); // Save the confirmed value
        setCurrentValue(modalValue); // Update the current value
        onChange && onChange(rowData.uid, dataKey, modalValue);
        setIsValueChanged(modalValue !== rowData[dataKey]); // Update the state to track value change
      }
      setIsConfirmModalOpen(!isConfirmModalOpen);
      setPendingValue(null);
    };

    const handleCancel = () => {
      setIsConfirmModalOpen(false);
      setCurrentValue(previousValue); // Revert to the previous value if canceled
    };

    return (
      <>
        <div>
          <Cell {...props} className={editing ? "table-content-editing" : ""}>
            <div style={{ display: "flex" }}>
              {editing && (
                <input
                  type="text"
                  style={{
                    width: "40px",
                    textAlign: "start",
                    color: isValueChanged ? "red" : "black", // Apply text color based on value change
                  }}
                  value={currentValue} // Bind to currentValue state
                  className="py-1 text-center outline-none"
                  onChange={handleChange}
                  onBlur={handleBlur} // Handle blur event to update the text color
                />
              )}
              {isValueChanged && (
                <div
                  style={{
                    right: "3px",
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  *
                </div>
              )}
            </div>
          </Cell>
        </div>
        {/* Negative value modal */}
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Modal.Header>
            <Modal.Title>Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>You cannot make a negative adjustment.</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setIsModalOpen(false)} appearance="primary">
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Blocked item warning modal */}
        <Modal
          open={isBlockWarningModalOpen}
          onClose={() => setIsBlockWarningModalOpen(false)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Modal.Header>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            To make an adjustment, first you need to take off the block!
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleBlockWarningConfirm} appearance="primary">
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Confirmation modal for large quantity */}
        <Modal
          open={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <Modal.Header>
            <Modal.Title>Large Quantity Entered</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please confirm the quantity</p>
            <Input
              type="text"
              value={modalValue}
              onChange={(value) => setModalValue(value)}
              style={{ marginTop: 10 }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleConfirm} appearance="primary">
              Confirm
            </Button>
            <Button onClick={handleCancel} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  const handleChange = (id, key, value) => {
    value = value ? value : "0";
    var nextData = Object.assign([], selectedReportTableName);
    var activeItem = Array(nextData.find((item) => item.uid === id));
    nextData.find((item) => item.uid === id)[key] = value;
    nextData.map((item) => {
      if (item.credit_Qty === "") return (item.user_Credit_Adj_Qty = "");
    });

    activeItem = activeItem.filter(
      (item) => item.user_Credit_Adj_Qty !== "" || item.user_Ord_Adj_Qty !== ""
    );
    if (
      nextData[id].credit_Qty === "" &&
      nextData[id].user_Credit_Adj_Qty === ""
    ) {
      function updateOrAddObjectByUID(array, newObj) {
        if (newObj.user_Ord_Adj_Qty === "0" || newObj.user_Ord_Adj_Qty === "") {
          newObj.user_Ord_Adj_Qty = "";
        }

        if (
          newObj.user_Credit_Adj_Qty === "0" ||
          newObj.user_Credit_Adj_Qty === ""
        ) {
          newObj.user_Credit_Adj_Qty = "";
        }
        // UID'ye göre mevcut objeyi bul
        const existingIndex = array.findIndex((obj) => obj.uid === newObj.uid);

        // Eğer mevcut obje bulunursa, onu güncelle
        if (existingIndex !== -1) {
          array[existingIndex] = newObj;
        } else {
          // Eğer mevcut obje bulunmazsa, yeni objeyi ekle
          array.push(newObj);
        }

        return array;
      }
      //const combined = [...activeCreateOrder, ...activeItem];
      const combined = updateOrAddObjectByUID(activeCreateOrder, activeItem[0]);
      // combined.filter((item) => {
      //   return (
      //     !(item.credit_Qty === "" && item.user_Credit_Adj_Qty) &&
      //     item.user_Ord_Adj_Qty !== ""
      //   );
      // });
      setDownloadCreateOrder(combined);
      setActiveCreateOrder(combined);
    }

    setData(nextData);
  };
  // const handleEditState = (id, status) => {
  //   console.log(status);
  //   const nextData = Object.assign([], data);

  //   const activeItem = Array(nextData.find((item) => item.uid === id));
  //   activeItem[0].status = activeItem[0].status ? null : "EDIT";
  //   if (status === "Save") {
  //     console.log(activeItem);
  //     const combined = [...activeCreateOrder, ...activeItem];
  //     setActiveCreateOrder(combined);
  //   }

  //   // getCreateData(
  //   //   activeItem &&
  //   //     (Number(activeItem.user_Ord_Adj_Qty) >= 1 ||
  //   //       Number(activeItem.user_Credit_Adj_Qty) >= 1)
  //   //     ? activeCreateOrder
  //   //     : null
  //   // );
  //   setData(nextData);
  // };

  const sendData = (value) => {
    //setIsDisplay(true);

    for (let index = 0; index < value.length; index++) {
      const element = value[index];
      getCreateData(
        element &&
          (Number(element.user_Ord_Adj_Qty) >= 1 ||
            Number(element.user_Credit_Adj_Qty) >= 1)
          ? element
          : null
      );
    }
    setActiveCreateOrder([]);
    setOpenCreate(false);
    setIsActiveMessage(false);
  };

  const sendAllProcess = () => {
    sendData(activeCreateOrder);
    getReportData(
      userContext && userContext.store ? userContext.store : userStore
    );
  };

  const quickOrder = (a) => {
    if (selectedReportTableName.length >= 1) {
      var quickData = selectedReportTableName.map((item) => {
        item.user_Ord_Adj_Qty = a;
        if (a === "") item.credit_Qty = a;
        return item;
      });
      setSelectedReporTableName(quickData);

      const newActiveOrders = [];

      for (let index = 0; index < selectedReportTableName.length; index++) {
        if (selectedReportTableName[index]["user_Ord_Adj_Qty"] === "1") {
          newActiveOrders.push(selectedReportTableName[index]);
        }
      }

      setActiveCreateOrder([...activeCreateOrder, ...newActiveOrders]);
    }
  };

  const handleSelectValue = (value) => {
    setSelectedReportType(value);
  };
  const [isRefreshModalOpen, setIsRefreshModalOpen] = useState(false);

  const handleRefreshPage = () => {
    window.location.reload();
  };

  // work
  const today = new Date().toLocaleDateString();

  const getCurrentTimeString = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const currentTime = getCurrentTimeString();

  const filterDataForPDF = (data) => {
    return data.map((item) => ({
      Item: item.item_ID,
      UPC: item.upc,
      Description: item.description,
      Size: item.size,
      Pack: item.pack,
      OrderOn: item.qty_Loaded,
      InvOn: item.inv_Qty,
      CreditReqQty: item.credit_Qty,
      ReqCreditQty: item.user_Credit_Adj_Qty,
      OrderNow: item.user_Ord_Adj_Qty,
      Day: item.dd,
      BackStock: item.back_Stock_SameDay_Qty,
      Block: item.blocked,
      WhyWait: item.why_Wait,
      WaitDaysFromToday: item.days_To_Wait,
    }));
  };

  const exportToPDF = async () => {
    try {
      // Generate PDF with the data
      const filteredData = filterDataForPDF(selectedReportTableName);
      const blob = await pdf(
        <PDFDocument
          data={filteredData}
          fromDate={fromDate.toLocaleDateString()}
          toDate={toDate.toLocaleDateString()}
          today={today}
          currentTime={currentTime.toString()}
        />
      ).toBlob(); // Pass data as a prop

      const url = URL.createObjectURL(blob); // Create a URL for the blob
      const link = document.createElement("a"); // Create a link element
      link.href = url; // Set link href to the blob URL
      link.download = "ReportOutOfStock.pdf"; // Set the default download name
      document.body.appendChild(link); // Append link to body
      link.click(); // Trigger the download
      document.body.removeChild(link); // Clean up
    } catch (error) {
      console.error("Error generating PDF:", error); // Log the error
    }
  };

  return (
    <>
      <div>
        <div>
          <div>
            <PageHeaderComponent
              setGroupTypeID={setGroupTypeID}
              setAwgOrGotSelect={setAwgOrGotSelect}
              setUserStore={setUserStore}
              userContext={userContext}
              groupAndGroupID={groupAndGroupID}
              awgOrGot={awgOrGot}
              storeName={storeName}
              selectedDesc={selectedDesc}
              selectedID={selectedID}
              storeData={storeData}
              handleDescChange={handleDescChange}
              storeNameHeader={storeNameHeader}
              storeID={storeID}
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              isVendor={true}
              refreshAll={true}
              handleRefreshPage={handleRefreshPage}
              allVendorName={allVendorName}
              onOptionChangeVendor={onOptionChangeVendor}
              isCalendar={false}
            />

            <div className="OrderInvoice">
              <div className="calendar-container">
                <div className="flex-container" style={{ marginTop: "11px" }}>
                  <DatePicker
                    label="OOS Date:"
                    oneTap
                    value={fromDate ? new Date(fromDate) : new Date()}
                    onChange={(value) => {
                      setFromDate(value);
                    }}
                    cleanable={false}
                    style={{ width: "260px", marginLeft: "10px" }} // Set a fixed width for the dropdown button
                    menuStyle={{ width: "260px" }} // Set the same fixed width for the dropdown menu
                  />
                  <div>
                    <DatePicker
                      appearance="default"
                      label="Invoice Date:"
                      oneTap
                      value={toDate ? new Date(toDate) : new Date()}
                      onChange={(value) => {
                        setToDate(value);
                      }}
                      style={{ width: "260px", marginLeft: "5px" }} // Set a fixed width for the dropdown button
                      menuStyle={{ width: "260px" }} // Set the same fixed width for the dropdown menu
                      cleanable={false}
                    />
                  </div>
                  <div>
                    <SelectPicker
                      onChange={handleSelectValue}
                      value={selectedReportType}
                      data={options}
                      label="Table"
                      cleanable={false}
                      style={{ width: "220px", marginLeft: "5px" }} // Set a fixed width for the dropdown button
                      menuStyle={{ width: "220px" }} // Set the same fixed width for the dropdown menu
                    />
                  </div>

                  <div>
                    <Button
                      onClick={() => getReportData(userStore)}
                      style={{
                        backgroundColor: "#007bff",
                        color: "#fff",
                        padding: "7px",
                        borderRadius: "5px",
                        fontSize: "14px",
                        display: "flex",
                        alignItems: "center",
                        textTransform: "none",
                        marginLeft: "5px",
                        marginTop: "2px",
                      }}
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </div>

              <div
                className="left-to-right d-flex flex-nowrap"
                style={{ marginLeft: "auto" }}
              >
                <div>
                  <div
                    style={{
                      marginRight: 7,
                      marginTop: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "16px",
                        }}
                      >
                        <Button
                          onClick={() => setIsRefreshModalOpen(true)}
                          style={{
                            backgroundColor: "#007bff",
                            color: "#fff",
                            borderRadius: "5px",
                            fontSize: "14px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center", // Center the content
                            textTransform: "none",
                            height: "36px", // Set the same height
                            width: "80px", // Set the same width
                            padding: "0", // Remove padding for consistent height
                          }}
                          disabled={isTableData ? false : true}
                        >
                          Submit
                        </Button>
                        <Button
                          size="md"
                          onClick={() => quickOrder("1")}
                          color="green"
                          appearance="primary"
                          style={{
                            borderRadius: "5px",
                            fontSize: "14px",
                            height: "36px", // Set the same height
                            width: "110px", // Set the same width
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center", // Center the content
                            padding: "0", // Remove padding for consistent height
                            marginLeft: "2px",
                          }}
                        >
                          Quick Order
                        </Button>
                      </div>
                    </div>
                  </div>

                  <Modal
                    open={isRefreshModalOpen}
                    onClose={() => setIsRefreshModalOpen(false)}
                    size="md"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Modal.Header>
                      <Modal.Title>Confirmation!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to save?</Modal.Body>
                    <Modal.Footer
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <Button
                          onClick={() => {
                            handleSave();
                            setIsRefreshModalOpen(false);
                          }}
                          style={{
                            backgroundColor: "#007bff",
                            color: "#fff",
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          onClick={() => setIsRefreshModalOpen(false)}
                          appearance="subtle"
                          style={{ background: "grey", color: "white" }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                </div>

                <Dropdown
                  renderToggle={(_props, _ref) => (
                    <div className="filter" ref={_ref} {..._props}>
                      <Tooltip title="Export to Excel" arrow>
                        <img
                          src={xlsxIcon}
                          alt="xls"
                          style={{
                            width: "30px",
                            height: "30px",
                            transition: "transform 0.3s",
                            cursor: "pointer",
                            fontSize: "30px", // Set the desired size here
                            marginLeft: "-7px",
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.transform = "scale(1.1)")
                          } // Scale on hover
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.transform = "scale(1)")
                          } // Reset scale on mouse leave
                          onClick={() => {
                            exportToExcel(
                              selectedReportTableName,
                              `Out Of Stock Report ${selectedReportType} Store: ${
                                userContext && userContext.store
                                  ? userContext.store
                                  : userStore
                              }`
                            );
                          }}
                        />
                      </Tooltip>
                    </div>
                  )}
                  style={{ marginTop: "30px" }}
                  className="ms-2 me-0 button-fixed"
                  appearance="link"
                  placement="bottomEnd"
                ></Dropdown>
                <Tooltip title="Download PDF" arrow>
                  <img
                    src={pdfIcon}
                    alt="export pdf"
                    style={{
                      width: "25px",
                      height: "28px",
                      cursor: "pointer", // Cursor style already set to pointer
                      marginTop: "30px",
                      marginLeft: "5px",
                    }}
                    onClick={exportToPDF}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>

        <TableComponent1
          isFromModal={false}
          tableDetails={selectedReportConstantName}
          reportList={selectedReportTableName}
          iconColumn={"description"}
          onTableRowClick={(e) => {}}
          EditableCell={EditableCell}
          handleChange={handleChange}
          onTableRowClickEye={(e) => {
            setOpenCreate(false);
            getDetailRecord(e);
          }}
          setOpenCreate={setOpenCreate}
          reportType={""}
          totalTableRow={{}}
          allRowDataRef={allRowDataRef}
          isTotalRowRequired={false}
        />

        <div className="scrollable-div">
          <Modal open={open} onClose={handleClose} style={{ width: "100%" }}>
            <div>
              <Modal.Header>
                <Modal.Title>
                  {" "}
                  <div>
                    <span
                      className="mx-2 filter"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <Icon icon="ep:back" />
                    </span>
                    <div style={{ textAlign: "center" }}>
                      Detail Out Of Stock
                    </div>
                  </div>
                  <div className="bg-container row m-0 mt-3 p-3">
                    <div className="col-12 col-md-2 p-2 reportDescItem">
                      <>
                        <div className="icon">
                          <Icon icon="material-symbols:store-outline" />
                        </div>
                        <div className="label">
                          <p className="m-0">Description</p>
                          <p className="m-1">
                            {detailRowData && detailRowData["description"]}
                          </p>
                        </div>
                      </>
                    </div>
                    <div className="col-12 col-md-2 p-2 reportDescItem">
                      <>
                        <div className="icon">
                          <Icon icon="material-symbols:store-outline" />
                        </div>
                        <div className="label">
                          <p className="m-0">Department</p>
                          <p className="m-1">
                            {detailRowData &&
                              detailRowData["department_Store_Name"]}
                          </p>
                        </div>
                      </>
                    </div>

                    <div className="col-12 col-md-2 p-2 reportDescItem">
                      <>
                        <div className="icon">
                          <Icon icon="material-symbols:store-outline" />
                        </div>
                        <div className="label">
                          <p className="m-0">Item ID</p>
                          <p className="m-1">
                            {detailRowData && detailRowData["item_ID"]}
                          </p>
                        </div>
                      </>
                    </div>
                    <div className="col-12 col-md-2 p-2 reportDescItem">
                      <>
                        <div className="icon">
                          <Icon icon="material-symbols:store-outline" />
                        </div>
                        <div className="label">
                          <p className="m-0">UPC</p>
                          <p className="m-1">
                            {detailRowData && detailRowData["upc"]}
                          </p>
                        </div>
                      </>
                    </div>
                    <div className="col-12 col-md-2 p-2 reportDescItem">
                      <>
                        <div className="icon">
                          <Icon icon="material-symbols:store-outline" />
                        </div>
                        <div className="label">
                          <p className="m-0">Pack</p>
                          <p className="m-1">
                            {detailRowData && detailRowData["pack"]}
                          </p>
                        </div>
                      </>
                    </div>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TableComponent
                  isFromModal={true}
                  tableDetails={ReportOOSConstants["DetailConstants"]}
                  reportList={detailData}
                  iconColumn={""}
                  onTableRowClick={() => {}}
                  reportType={""}
                  totalTableRow={{}}
                  isTotalRowRequired={false}
                />
              </Modal.Body>
            </div>
          </Modal>

          {confirmSearch && (
            <Modal
              open={confirmSearch}
              onClose={() => setConfirmSearch(false)}
              size="lg"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Modal.Header>
                <Modal.Title>Warning!</Modal.Title>
              </Modal.Header>
              <Modal.Body>All current changes will be lost!</Modal.Body>
              <Modal.Footer
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  <Button
                    onClick={() => setConfirmSearch(false)}
                    appearance="subtle"
                    style={{ background: "grey", color: "white" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleModalSearchClick}
                    style={{
                      backgroundColor: "#007bff",
                      color: "#fff",
                    }}
                  >
                    OK
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default ReportOutOfStockComp;
