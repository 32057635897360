/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useEffect, useState, useRef } from "react";
import { Dropdown, Input, InputGroup, Tabs } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import Calendar from "../../../components/common/CalendarComponent";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as ReportService from "../../../services/reportServices";
import * as SettingsServices from "../../../services/settingsServices";
import PageHeaderComponent from "../../../components/common/pageHeaderComponent";
import Constants from "../../.././utils/constants";
import TableComponent from "../../common/tableComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import { ReportConstants } from "./ReportCreditsConstants";
import { SelectPicker } from "rsuite";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { addDays } from "date-fns";
import * as UMSService from "../../../services/umsService";
import * as AuthService from "../../../services/authService";

const ReportCreditsComponent = (props: any) => {
  // const [fromDate, setFromDate] = useState<Date>(
  //   new Date(
  //     new Date().getFullYear(),
  //     new Date().getMonth(),
  //     new Date().getDate() - 7
  //   )
  // );

  const {
    chosenGroup,
    chosenGroupType,
    chosenGroupTypeName,
    chosenGroupName,
    chosenStore,
    chosenStoreName,
    chosenFromDate,
    chosenToDate,
    storeRecords,
    groupRecords,
  } = useSelector((state: RootState) => state?.filterSelection);

  // Calendar
  const [pendingData, setPendingData] = useState(null);
  const [paidData, setPaidData] = useState(null);
  const [notApprovedData, setNotApprovedData] = useState(null);
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));
  // const [toDate, setToDate] = useState<Date>(new Date());

  const [allVendorName, setAllVendorName] = useState([]);
  const [vendorID, setVendorID] = useState(allVendorName[1]);
  const [storeName, setStoreName] = useState([]);
  const [selectedID, setSelectedID] = useState(null);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [storeData, setStoreData] = useState([]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);
  const [storeID, setStoreID] = useState([]);
  const [userStore, setUserStore] = useState(storeID[0]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [selectedReportType, setSelectedReportType] = useState("Pending");
  const [tabsActiveKey, setTabsActiveKey] = useState("Pending");
  const [selectedReportConstantName, setSelectedReporConstantName] = useState(
    ReportConstants["Pending"]
  );
  const [selectedReportTableName, setSelectedReporTableName] =
    useState(pendingData);
  const { userContext = {} } = props;

  const handleDescChange = (value) => {
    setSelectedDesc(value);
    const selectedStore = storeData.find((item) => item.desc === value);
    if (selectedStore) {
      setSelectedID(selectedStore.id); // Set corresponding ID in the input box
    }
  };

  useEffect(() => {
    getReportData(
      userContext && userContext.store ? userContext.store : userStore
    );
  }, [userStore, storeNameHeader, fromDate, toDate, userContext]);
  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);

  const awgOrGot = [
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ];

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext } = props;
    const { user_ID, store } = userContext;
    var storesArray = [];
    const uniqueVendor = new Set();
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          const storeArray = result.records.map((item) => ({
            id: item.store_ID,
            desc: item.store_Name,
          }));

          const uniquestores = [
            ...new Map(storeArray.map((item) => [item.id, item])).values(),
          ];

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
          setStoreData(uniquestores);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          const storeArray = result.records.map((item) => ({
            id: item.store_ID,
            desc: item.store_Name,
          }));

          const uniquestores = [
            ...new Map(storeArray.map((item) => [item.id, item])).values(),
          ];

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
          setStoreData(uniquestores);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };
  /* const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext } = props;
    const { user_ID } = userContext;
    var storesArray = [];
    const uniqueVendor = new Set();
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    SettingsServices.getVendorStoreName(grouptypeID1, "")
      .then((result) => {
        result.records.sort((a, b) => {
          const Store_A = a.store_ID;
          const Store_B = b.store_ID;

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        if (storesArray.length >= 1) {
          result.records = result.records.filter((item) =>
            storesArray.includes(item.store_ID.toString())
          );
        }
        //console.log("StoreName: Result - ", result.records);
        var setArrayID = [];
        var setArrayName = [];
        result.records.filter((item) => {
          if (!setArrayID.includes(item.store_ID)) {
            setArrayID.push(item.store_ID);
          }
        });
        result.records.filter((item) => {
          if (!setArrayName.includes(item.store_Name)) {
            setArrayName.push(item.store_Name);
          }
        });
        setStoreName(setArrayName);
        chosenStore === 0 ? setUserStore(setArrayID[0]) : chosenStore;
        setStoreID(setArrayID);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  }; */

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);
  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          // Modify the description if IDType is "2"
          if (IDType === "2") {
            result = result.map((item) => {
              return {
                ...item,
                groupStore_desc:
                  item.groupStore_uid + " / " + item.groupStore_desc,
              };
            });
          }

          // Sort the result by groupStore_uid
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            return Store_A < Store_B ? -1 : Store_A > Store_B ? 1 : 0;
          });

          // Map the result to store both id and desc in one object
          const storeArray = result.map((item) => ({
            id: item.groupStore_uid,
            desc: item.groupStore_desc,
          }));

          // Set the mapped array to the state
          setStoreData(storeArray);
          if (storeArray.length > 0) {
            setSelectedDesc(storeArray[0].desc);
            setSelectedID(storeArray[0].id);
          }

          // Log the storeData for debugging
          console.log("storeData: ", storeData);

          // Set the chosen store or the first store in the list
          if (chosenStore === 0) {
            setUserStore(storeArray[0]?.id);
          }
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const getReportData = async (storeID) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;
    const selectedStoreID = chosenStore ?? storeID;
    const selectedFromDate = chosenFromDate ?? fromDate;
    const selectedToDate = chosenToDate ?? toDate;

    if (store !== 0) {
      handleSpinner && handleSpinner(true);
      try {
        const [PendingData, PaidData, NotApprovedData] = await Promise.all([
          ReportService.getReportCreditsPending(
            store.toString(),
            moment(selectedFromDate).format("YYYY-MM-DD"),
            moment(selectedToDate).format("YYYY-MM-DD")
          ),
          ReportService.getReportCreditsPaid(
            store.toString(),
            moment(selectedFromDate).format("YYYY-MM-DD"),
            moment(selectedToDate).format("YYYY-MM-DD")
          ),
          ReportService.getReportCreditsNotApproved(
            store.toString(),
            moment(selectedFromDate).format("YYYY-MM-DD"),
            moment(selectedToDate).format("YYYY-MM-DD")
          ),
        ]);

        modifyReportData(PendingData.records);
        modifyReportData(PaidData.records);
        modifyReportData(NotApprovedData.records);
        setPendingData(PendingData.records);
        setSelectedReporConstantName(ReportConstants["Pending"]);
        setSelectedReporTableName(PendingData.records);
        setPaidData(PaidData.records);
        setNotApprovedData(NotApprovedData.records);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
        handleSpinner && handleSpinner(false);
      }
    } else {
      handleSpinner && handleSpinner(true);
      try {
        const [PendingData, PaidData, NotApprovedData] = await Promise.all([
          ReportService.getReportCreditsPending(
            storeID.toString(),
            chosenFromDate
              ? moment(chosenFromDate).format("YYYY-MM-DD")
              : moment(fromDate).format("YYYY-MM-DD"),
            chosenToDate
              ? moment(chosenToDate).format("YYYY-MM-DD")
              : moment(toDate).format("YYYY-MM-DD")
          ),
          ReportService.getReportCreditsPaid(
            storeID.toString(),
            chosenFromDate
              ? moment(chosenFromDate).format("YYYY-MM-DD")
              : moment(fromDate).format("YYYY-MM-DD"),
            chosenToDate
              ? moment(chosenToDate).format("YYYY-MM-DD")
              : moment(toDate).format("YYYY-MM-DD")
          ),
          ReportService.getReportCreditsNotApproved(
            storeID.toString(),
            chosenFromDate
              ? moment(chosenFromDate).format("YYYY-MM-DD")
              : moment(fromDate).format("YYYY-MM-DD"),
            chosenToDate
              ? moment(chosenToDate).format("YYYY-MM-DD")
              : moment(toDate).format("YYYY-MM-DD")
          ),
        ]);

        modifyReportData(PendingData.records);
        modifyReportData(PaidData.records);
        modifyReportData(NotApprovedData.records);
        setPendingData(PendingData.records);
        setSelectedReporConstantName(ReportConstants["Pending"]);
        setSelectedReporTableName(PendingData.records);
        setPaidData(PaidData.records);
        setNotApprovedData(NotApprovedData.records);
      } catch (err) {
        console.error("Error: Get Reports - ", err);
      } finally {
        handleSpinner && handleSpinner(false);
      }
    }
  };

  // const getReportData = async (storeID) => {
  //   const { handleSpinner, userContext = {} } = props;
  //   const { store } = userContext;
  //   if (store !== 0) {
  //     handleSpinner && handleSpinner(true);
  //     ReportService.getReportCredits(store.toString())
  //       .then((result) => {
  //         //console.log("Credits: Result - ", result);
  //         const response = modifyReportData(result.records);
  //         // console.log("Credits: Report - ", response);
  //         setReportList(response);
  //         setAllConfDataFiltered(response);
  //       })
  //       .catch((err) => {
  //         console.error("Error: Get Orders - ", err);
  //         // toast.error("Error Loading Orders");
  //       })
  //       .finally(() => {
  //         handleSpinner && handleSpinner(false);
  //       });
  //   } else {
  //     handleSpinner && handleSpinner(true);
  //     ReportService.getReportCredits(storeID.toString())
  //       .then((result) => {
  //         //console.log("Credits: Result - ", result);
  //         const response = modifyReportData(result.records);
  //         // console.log("Credits: Report - ", response);
  //         setReportList(response);
  //         setAllConfDataFiltered(response);
  //         // response.length > 0 && toast.success("Report Loaded Successfully");
  //       })
  //       .catch((err) => {
  //         console.error("Error: Get Orders - ", err);
  //         // toast.error("Error Loading Orders");
  //       })
  //       .finally(() => {
  //         handleSpinner && handleSpinner(false);
  //       });
  //   }
  // };

  const modifyReportData = (data) => {
    let modifiedData = data.map((item) => {
      item["dateTime_In"] =
        item["dateTime_In"] && moment(item["dateTime_In"]).format("MM-DD-YYYY");
      item["invoice_Date"] =
        item["invoice_Date"] &&
        moment(item["invoice_Date"]).format("MM-DD-YYYY");
      item["invoice_Date_Next"] =
        item["invoice_Date_Next"] &&
        moment(item["invoice_Date_Next"]).format("MM-DD-YYYY");
      item["delivery_Date"] =
        item["delivery_Date"] &&
        moment(item["delivery_Date"]).format("MM-DD-YYYY");

      item["rec_Date"] =
        item["rec_Date"] && moment(item["rec_Date"]).format("MM-DD-YYYY");

      return item;
    });
    return modifiedData;
  };

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion

  //#region Filters Drawer

  //#endregion

  useEffect(() => {
    if (userContext && userContext.store && userContext.store !== 0) {
      getStoreName(userContext.store);
    }
  }, [userContext]);

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const filterList = (value) => {
    if (value) {
      setSelectedReporTableName(
        selectedReportTableName.filter((item) =>
          item.confirmation_ID
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        )
      );
    } else {
      selectedData(selectedReportType);
    }
  };
  useEffect(() => {
    selectedData(selectedReportType);
  }, [selectedReportType, fromDate, toDate, userStore]);
  const selectedData = (selectedTab) => {
    setSelectedReporConstantName(ReportConstants[selectedTab]);
    setTabsActiveKey("Pending");
    switch (selectedTab) {
      case "Pending":
        setSelectedReporTableName(pendingData);
        break;
      case "Paid":
        setSelectedReporTableName(paidData);
        break;
      case "NotApproved":
        setSelectedReporTableName(notApprovedData);
        break;
    }
  };

  const containerRef = useRef(null);
  const [isGroupGrind, setIsGroupGrind] = useState(false);

  const SelectGroupType = (event) => {
    setGroupTypeID(event);
    setUserStore("");
    setIsGroupGrind(true);
  };

  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event;
    setUserStore(storeID[VendorAndStore]);
    setIsGroupGrind(false);
  };

  const calenderOnClick = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
  };

  return (
    <>
      <div>
        <div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              marginBottom: "1rem",
            }}
          >
            {/* Group */}
            {/*   <div ref={containerRef} style={{ position: "relative" }}>
              {!(
                userContext &&
                userContext.groupType === 2 &&
                userContext.groupTypeId === 0
              ) && (
                  <SelectPicker
                    container={() => containerRef.current}
                    onChange={SelectGroupType}
                    style={{ width: "auto", minWidth: "200px" }}
                    label="Group"
                    virtualized
                    data={groupAndGroupID.map((item) => ({
                      label: item.groupStore_desc,
                      value: item.groupStore_uid,
                    }))}
                    cleanable={false}
                  />
                )}
            </div> */}

            {/* store */}
            {/*  <div ref={containerRef} style={{ position: "relative" }}>
              <SelectPicker
                onChange={onOptionChangeHandler}
                container={() => containerRef.current}
                style={{ width: "auto", minWidth: "200px" }}
                label="Store"
                placeholder={
                  (userContext?.groupType === 3 &&
                    userContext.groupTypeId === 0) ||
                    isGroupGrind
                    ? "All"
                    : storeName && storeName[0]
                      ? storeName[0]
                      : "All"
                }
                virtualized
                data={storeName.map((item, index) => ({
                  label: item,
                  value: index,
                }))}
                cleanable={false}
              />
            </div> */}

            {/*  <div ref={containerRef} style={{ position: "relative" }}>
              <Calendar
                value={[fromDate, toDate]}
                calenderOnClick={calenderOnClick}
                cleanable={false}
              />
            </div> */}

            <PageHeaderComponent
              setGroupTypeID={setGroupTypeID}
              setAwgOrGotSelect={setAwgOrGotSelect}
              setUserStore={setUserStore}
              userContext={userContext}
              groupAndGroupID={groupAndGroupID}
              awgOrGot={awgOrGot}
              storeName={storeName}
              storeNameHeader={storeNameHeader}
              storeID={storeID}
              fromDate={chosenFromDate ? chosenFromDate : fromDate}
              toDate={chosenToDate ? chosenToDate : toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              isVendor={false}
              isCalendar={true}
              storeData={storeData}
              selectedID={selectedID}
              setSelectedID={setSelectedID}
              selectedDesc={selectedDesc}
              handleDescChange={handleDescChange}
            />
          </div>

          <div className="Tabs">
            <Tabs
              defaultActiveKey={tabsActiveKey}
              onSelect={(e) => setSelectedReportType(e)}
            >
              <Tabs.Tab eventKey="Pending" title="Pending"></Tabs.Tab>
              <Tabs.Tab eventKey="Paid" title="Paid"></Tabs.Tab>
              <Tabs.Tab eventKey="NotApproved" title="Not Approved"></Tabs.Tab>
            </Tabs>
          </div>
          <div className="Tabs">
            <InputGroup
              onChange={(event) => {
                filterList(event.target.value);
              }}
              style={{
                width: 220,
                height: 40,
                fontWeight: 800,
              }}
            >
              <Input placeholder="Filter Confirmation ID" />
              <InputGroup.Button>
                <SearchIcon />
              </InputGroup.Button>
            </InputGroup>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "16px",
            }}
          >
            <Dropdown
              renderToggle={(_props, _ref) => (
                <div className="filter" ref={_ref} {..._props}>
                  <Icon icon="pajamas:export" /> Export
                </div>
              )}
              className="ms-2 me-0 button-fixed"
              appearance="link"
              placement="bottomEnd"
            >
              <Dropdown.Item
                icon={<FileDownloadIcon />}
                onClick={() => {
                  exportToExcel(
                    selectedReportTableName,
                    `Credits Report ${selectedReportType} Store: ${
                      userContext && userContext.store
                        ? userContext.store
                        : userStore
                    }`
                  );
                }}
              >
                Export to Excel
              </Dropdown.Item>
            </Dropdown>
          </div>

          <TableComponent
            isFromModal={false}
            tableDetails={selectedReportConstantName}
            reportList={selectedReportTableName}
            iconColumn={""}
            onTableRowClick={() => {}}
            reportType={""}
            totalTableRow={{}}
            isTotalRowRequired={false}
          />
        </div>
      </div>
    </>
  );
};

export default ReportCreditsComponent;
