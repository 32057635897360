/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import {
  Dropdown,
  Input,
  InputGroup,
  InputPicker,
  Whisper,
  Tooltip,
  Button,
  Grid,
  Row,
  Col,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { SelectPicker } from "rsuite";
import xlsxIcon from "../../../../assets/images/xlsxIcon.png";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as ReportService from "../../../../services/reportServices";
import * as SettingsServices from "../../../../services/settingsServices";
import PageHeaderComponent from "../../../../components/common/pageHeaderComponent";
import Constants from "../../../.././utils/constants";
import TableComponent from "../../../common/tableComponent";
import { Icon } from "@iconify/react";
import moment from "moment";
import { ReportConstants } from "./ReportSectionsToScanConstants";
import * as UMSService from "../../../../services/umsService";
import * as AuthService from "../../../../services/authService";

import { add, addDays } from "date-fns";
const ReportSectionsToScan = (props: any) => {
  const { userContext = {} } = props;

  // const [toDate, setToDate] = useState<Date>(new Date());
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), -1));
  const [storeName, setStoreName] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [selectedID, setSelectedID] = useState(null);
  const [storeNameHeader, setStoreNameHeader] = useState([]);
  const [storeID, setStoreID] = useState([]);
  const [userStore, setUserStore] = useState(storeID[0]);
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [inputValueConfID, setInputValueConfID] = useState("");
  const [allVendorName, setAllVendorName] = useState([]);
  const [vendorID, setVendorID] = useState(allVendorName[1]);

  // Sections Data
  const [sectionsData, setSectionsData] = useState([]);
  const [allSectionsFiltered, setAllSectionsFiltered] = useState([]);

  // Placement History Data

  const [HistoryData, setHistoryData] = useState([]);
  const [allHistoryFiltered, setAllHistoryFiltered] = useState([]);

  // Placement Active Placement

  const [ActivePlacementData, setActivePlacementData] = useState([]);
  const [allActivePlacementFiltered, setAllActivePlacementFiltered] = useState(
    []
  );

  // Placement Store Placement Summary
  const [storePlacementSummaryData, setStorePlacementSummmaryData] = useState(
    []
  );
  const [
    allStorePlacementSummaryFiltered,
    setAllStorePlacementSummaryFiltered,
  ] = useState([]);

  const [storePlacementTotal, setStorePlacementTotal] = useState({});

  // Placement Group Placement Summary
  const [groupPlacementSummaryData, setGroupPlacementSummmaryData] = useState(
    []
  );
  const [
    allGroupPlacementSummaryFiltered,
    setAllGroupPlacementSummaryFiltered,
  ] = useState([]);

  const [groupPlacementTotal, setGroupPlacementTotal] = useState({});

  // Placement By Confirmation

  const [placementByConfData, setPlacementByConfData] = useState([]);
  const [allPlacementByConfFiltered, setAllPlacementByConfFiltered] = useState(
    []
  );

  const [selectedTab, setSelectedTab] = useState({
    value: "1",
    label: "Active Placement",
  });
  const handleDescChange = (value) => {
    setSelectedDesc(value);
    const selectedStore = storeData.find((item) => item.desc === value);
    if (selectedStore) {
      setSelectedID(selectedStore.id); // Set corresponding ID in the input box
    }
  };

  const [tabsList, setTabsList] = useState([
    { value: "1", label: "Active Placement" },
    { value: "2", label: "Sections to scan" },
    { value: "3", label: "Placement History" },
    { value: "4", label: "Store Summary" },
    { value: "6", label: "Placement by Confirmation#" },
  ]);

  useEffect(() => {
    if (userContext) {
      if (userContext.roleName === "Super Admin" || userContext.store === 0) {
        setTabsList([
          { value: "1", label: "Active Placement" },
          { value: "2", label: "Sections to scan" },
          { value: "3", label: "Placement History" },
          { value: "4", label: "Store Summary" },
          { value: "5", label: "Group Summary" },
          { value: "6", label: "Placement by Confirmation#" },
        ]);
      }
    }
  }, [userContext]);

  const data = tabsList.map((item) => {
    return { label: item.label, value: item.value };
  });

  // Sections to Scan
  useEffect(() => {
    if (selectedTab.value === "2") {
      getReportData(
        userContext && userContext.store ? userContext.store : userStore
      );
    }
  }, [userStore, storeNameHeader, userContext, selectedTab]);

  // Placement History
  const executeReport = () => {
    inputValue &&
      getPlacementHistory(
        userContext && userContext.store ? userContext.store : userStore,
        inputValue
      );
  };

  // Placement History
  const executeReportByConfID = () => {
    inputValueConfID &&
      getPlacementByConfID(
        userContext && userContext.store ? userContext.store : userStore,
        inputValueConfID
      );
  };
  // Active Placement
  useEffect(() => {
    if (selectedTab.value === "1") {
      getActivePlacement(
        userContext && userContext.store ? userContext.store : userStore
      );
    }
  }, [userStore, storeNameHeader, userContext, selectedTab]);

  // Store Placement Summary
  useEffect(() => {
    if (selectedTab.value === "4") {
      getStorePlacementSummary(
        userContext && userContext.store ? userContext.store : userStore,
        "1",
        fromDate,
        toDate
      );
    }
  }, [userStore, storeNameHeader, userContext, fromDate, toDate, selectedTab]);

  // Group Placement Summary
  useEffect(() => {
    if (userContext && userContext.store === 0)
      if (selectedTab.value === "5") {
        getGroupPlacementSummary(
          groupTypeID ? groupTypeID : userContext && userContext.groupTypeId,
          fromDate,
          toDate
        );
      }
  }, [userContext, fromDate, toDate, groupTypeID, selectedTab]);

  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);

  const awgOrGot = [
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ];

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext } = props;
    const { user_ID, store } = userContext;
    var storesArray = [];
    const uniqueVendor = new Set();
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          const storeArray = result.records.map((item) => ({
            id: item.store_ID,
            desc: item.store_Name,
          }));

          const uniquestores = [
            ...new Map(storeArray.map((item) => [item.id, item])).values(),
          ];

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
          setStoreData(uniquestores);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          var setArrayID = [];
          var setArrayName = [];
          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          const storeArray = result.records.map((item) => ({
            id: item.store_ID,
            desc: item.store_Name,
          }));

          const uniquestores = [
            ...new Map(storeArray.map((item) => [item.id, item])).values(),
          ];

          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
          setStoreData(uniquestores);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };
  /* const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext } = props;
    const { user_ID } = userContext;
    var storesArray = [];
    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    SettingsServices.getVendorStoreName(grouptypeID1, "")
      .then((result) => {
        result.records.sort((a, b) => {
          const Store_A = a.store_ID;
          const Store_B = b.store_ID;

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        if (storesArray.length >= 1) {
          result.records = result.records.filter((item) =>
            storesArray.includes(item.store_ID.toString())
          );
        }
        //console.log("StoreName: Result - ", result.records);
        var setArrayID = [];
        var setArrayName = [];
        result.records.filter((item) => {
          if (!setArrayID.includes(item.store_ID)) {
            setArrayID.push(item.store_ID);
          }
        });
        result.records.filter((item) => {
          if (!setArrayName.includes(item.store_Name)) {
            setArrayName.push(item.store_Name);
          }
        });

        setStoreName(setArrayName);
        setUserStore(setArrayID[0]);
        setStoreID(setArrayID);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      })
      .finally(() => {
        handleSpinner && handleSpinner(false);
      });
  }; */

  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);
  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });

          const storeArray = result.map((item) => ({
            id: item.groupStore_uid,
            desc: item.groupStore_desc,
          }));
          if (storeArray.length > 0) {
            setSelectedDesc(storeArray[0].desc);
            setSelectedID(storeArray[0].id);
          }

          setStoreData(storeArray);
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId &&
      userContext.groupType === "" &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        if (IDType === "2") {
          result.map((item) => {
            return (item.groupStore_desc =
              item.groupStore_uid + " / " + item.groupStore_desc);
          });
        }
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  // const getReportData = async (storeID) => {
  //   const { handleSpinner, userContext = {} } = props;
  //   const { store } = userContext;
  //   if (store !== 0) {
  //     handleSpinner && handleSpinner(true);
  //     try {
  //       const [PendingData] = await Promise.all([
  //         ReportService.getReportCreditsPending(
  //           storeID.toString(),
  //           moment(fromDate).format("YYYY-MM-DD"),
  //           moment(toDate).format("YYYY-MM-DD")
  //         ),

  //       ]);

  //     } catch (err) {
  //       console.error("Error: Get Reports - ", err);
  //     } finally {
  //       handleSpinner && handleSpinner(false);
  //     }
  //   } else {
  //     handleSpinner && handleSpinner(true);
  //     try {
  //       const [PendingData, PaidData, NotApprovedData] = await Promise.all([
  //         ReportService.getReportCreditsPending(
  //           storeID.toString(),
  //           moment(fromDate).format("YYYY-MM-DD"),
  //           moment(toDate).format("YYYY-MM-DD")
  //         ),

  //       ]);

  //     } catch (err) {
  //       console.error("Error: Get Reports - ", err);
  //     } finally {
  //       handleSpinner && handleSpinner(false);
  //     }
  //   }
  // };

  // Report Sections to Scan
  const getReportData = async (storeID) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;
    storeID && handleSpinner && handleSpinner(true);

    if (store !== 0) {
      handleSpinner && handleSpinner(true);
      ReportService.getSectionsToScan(store.toString(), 40)
        .then((result) => {
          //console.log("Credits: Result - ", result);
          // console.log("Credits: Report - ", response);
          modifyReportData(result.allRecords);
          setSectionsData(result.allRecords);
          setAllSectionsFiltered(result.allRecords);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      ReportService.getSectionsToScan(storeID.toString(), 40)
        .then((result) => {
          //console.log("Credits: Result - ", result);
          // console.log("Credits: Report - ", response);
          // response.length > 0 && toast.success("Report Loaded Successfully");
          handleSpinner && handleSpinner(true);

          modifyReportData(result.allRecords);
          setSectionsData(result.allRecords);
          setAllSectionsFiltered(result.allRecords);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  // Report Placement History
  const getPlacementHistory = async (storeID, UPC) => {
    UPC = UPC === "" ? 0 : UPC;
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;
    if (store !== 0) {
      handleSpinner && handleSpinner(true);
      ReportService.getPlacementHistory(store.toString(), UPC.toString())
        .then((result) => {
          result.allRecords.map(
            (item) =>
              (item["mod_Date"] =
                item["mod_Date"] &&
                moment(item["mod_Date"]).format("MM-DD-YYYY"))
          );

          result.allRecords.map(
            (item) =>
              (item["date_Discontinue"] =
                item["date_Discontinue"] &&
                moment(item["date_Discontinue"]).format("MM-DD-YYYY"))
          );
          setHistoryData(result.allRecords);
          setAllHistoryFiltered(result.allRecords);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      handleSpinner && handleSpinner(true);
      ReportService.getPlacementHistory(storeID.toString(), UPC.toString())
        .then((result) => {
          result.allRecords.map(
            (item) =>
              (item["mod_Date"] =
                item["mod_Date"] &&
                moment(item["mod_Date"]).format("MM-DD-YYYY"))
          );
          result.allRecords.map(
            (item) =>
              (item["date_Discontinue"] =
                item["date_Discontinue"] &&
                moment(item["date_Discontinue"]).format("MM-DD-YYYY"))
          );

          setHistoryData(result.allRecords);
          setAllHistoryFiltered(result.allRecords);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  // Report Active Placement
  const getActivePlacement = async (storeID) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;
    storeID && handleSpinner && handleSpinner(true);

    if (store !== 0) {
      ReportService.getActivePlacement(store.toString())
        .then((result) => {
          result.allRecords.map(
            (item) =>
              (item["date_Discontinue"] =
                item["date_Discontinue"] &&
                moment(item["date_Discontinue"]).format("MM-DD-YYYY"))
          );
          setActivePlacementData(result.allRecords);
          setAllActivePlacementFiltered(result.allRecords);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      handleSpinner && handleSpinner(true);

      ReportService.getActivePlacement(storeID.toString())
        .then((result) => {
          result.allRecords.map(
            (item) =>
              (item["date_Discontinue"] =
                item["date_Discontinue"] &&
                moment(item["date_Discontinue"]).format("MM-DD-YYYY"))
          );
          setActivePlacementData(result.allRecords);
          setAllActivePlacementFiltered(result.allRecords);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };
  function calculateTotals(data) {
    function mintoHours(min) {
      var hours = Math.floor(min / 60);
      var remainMin = min % 60;
      return hours + " h " + remainMin + " min";
    }
    let totalitems = 0;
    let totalTime = 0;
    let totalScanTime = 0;
    let avgScanTime = 0;
    let totalSections = 0;
    let totalShelves = 0;

    for (let entry of data) {
      totalitems += Number(entry.cnt) || 0;
      totalTime += Number(entry.total_Min1) || 0;
      totalScanTime += Number(entry.total_Scan1) || 0;
      avgScanTime += Number(entry.avg_ScanTime) || 0;
      totalSections += Number(entry.section_Cnt) || 0;
      totalShelves += Number(entry.shelf_Cnt) || 0;
    }

    return {
      cnt: totalitems,
      total_Min: mintoHours(totalTime),
      total_Scan: mintoHours(totalScanTime),
      avg_ScanTime1: avgScanTime
        ? (avgScanTime / data.length).toFixed(2).toString() + " sec"
        : 0,
      section_Cnt: totalSections,
      shelf_Cnt: totalShelves,
    };
  }
  // Report Store Placement Sumary
  const getStorePlacementSummary = async (
    nID,
    nID_Type,
    Start_Time,
    End_Time
  ) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;
    handleSpinner && handleSpinner(true);

    if (store !== 0) {
      handleSpinner && handleSpinner(true);
      ReportService.getPlacementStoreSummary(
        store.toString(),
        "1",
        moment(Start_Time).format("MM/DD/YYYY"),
        moment(End_Time).format("MM/DD/YYYY")
      )
        .then((result) => {
          modifyStoreSummaryData(result.allRecords);
          setStorePlacementTotal(calculateTotals(result.allRecords));
          setStorePlacementSummmaryData(result.allRecords);
          setAllStorePlacementSummaryFiltered(result.allRecords);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      ReportService.getPlacementStoreSummary(
        nID,
        "1",
        moment(fromDate).format("MM/DD/YYYY"),
        moment(toDate).format("MM/DD/YYYY")
      )
        .then((result) => {
          modifyStoreSummaryData(result.allRecords);
          setStorePlacementTotal(calculateTotals(result.allRecords));
          setStorePlacementSummmaryData(result.allRecords);
          setAllStorePlacementSummaryFiltered(result.allRecords);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  // Report Placement by Confirmation ID
  const getPlacementByConfID = async (Store_ID, ConfID) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;
    handleSpinner && handleSpinner(true);

    if (store !== 0) {
      handleSpinner && handleSpinner(true);
      ReportService.getPlacementByConfID(store.toString(), ConfID)
        .then((result) => {
          result.allRecords.map(
            (item) => (item.barcode_2D = item.barcode_2D.trim())
          );

          result.allRecords.map(
            (item) =>
              (item.received = moment(item.received).format(
                "MM/DD/YYYY HH:mm A"
              ))
          );

          result.allRecords.map(
            (item) =>
              (item.date_Time = moment(item.date_Time).format("MM/DD/YYYY"))
          );
          setAllPlacementByConfFiltered(result.allRecords);
          setPlacementByConfData(result.allRecords);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      handleSpinner && handleSpinner(true);

      ReportService.getPlacementByConfID(Store_ID, ConfID)
        .then((result) => {
          result.allRecords.map(
            (item) => (item.barcode_2D = item.barcode_2D.trim())
          );

          result.allRecords.map(
            (item) =>
              (item.received = moment(item.received).format(
                "MM/DD/YYYY HH:mm A"
              ))
          );

          result.allRecords.map(
            (item) =>
              (item.date_Time = moment(item.date_Time).format("MM/DD/YYYY"))
          );

          setAllPlacementByConfFiltered(result.allRecords);
          setPlacementByConfData(result.allRecords);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  // Report Group Placement Summary
  const getGroupPlacementSummary = async (nID, Start_Time, End_Time) => {
    const { handleSpinner, userContext = {} } = props;
    const { store } = userContext;
    handleSpinner && handleSpinner(true);

    if (nID !== 0) {
      ReportService.getPlacementGroupSummary(
        nID,
        moment(fromDate).format("MM/DD/YYYY"),
        moment(toDate).format("MM/DD/YYYY")
      )
        .then((result) => {
          setGroupPlacementTotal(calculateTotalsGroup(result.allRecords));
          modifyGroupSummaryData(result.allRecords);
          setGroupPlacementSummmaryData(result.allRecords);
          setAllGroupPlacementSummaryFiltered(result.allRecords);
        })
        .catch((err) => {
          console.error("Error: Get Orders - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  function calculateTotalsGroup(data) {
    const convertSeconds = (seconds: number): string => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      return `${hours} h ${minutes} min`;
    };
    let totalitems = 0;
    let totalTime = 0;
    let totalScanTime = 0;
    let avgScanTime = 0;
    let totalSections = 0;
    let totalShelves = 0;

    for (let entry of data) {
      totalitems += Number(entry.cnt) || 0;
      totalTime += Number(entry.total_Time) || 0;
      totalScanTime += Number(entry.scan_Seconds) || 0;
      avgScanTime += Number(entry.avg_ScanTime) || 0;
      totalSections += Number(entry.section_Cnt) || 0;
      totalShelves += Number(entry.shelf_Cnt) || 0;
    }

    return {
      cnt: totalitems,
      total_Time: convertSeconds(totalTime),
      scan_Seconds: convertSeconds(totalScanTime),
      avg_ScanTime: avgScanTime
        ? (avgScanTime / data.length).toFixed(2).toString() + " sec"
        : 0,
      section_Cnt: totalSections,
      shelf_Cnt: totalShelves,
    };
  }

  const modifyReportData = (data) => {
    let modifiedData = data.map((item) => {
      item["need_To_Scan"] =
        item["need_To_Scan"] &&
        moment(item["need_To_Scan"]).format("MM-DD-YYYY");

      return item;
    });
    return modifiedData;
  };

  // Store Summary Modify Data
  const modifyStoreSummaryData = (data) => {
    let modifiedData = data.map((item) => {
      item["total_Min"] =
        moment(item["end_Scan"]).diff(moment(item["start_Scan"]), "minutes") +
        " min";
      item["total_Scan"] = Math.floor(item["scan_Seconds"] / 60) + " min";
      item["total_Min1"] = moment(item["end_Scan"]).diff(
        moment(item["start_Scan"]),
        "minutes"
      );
      item["total_Scan1"] = Math.floor(item["scan_Seconds"] / 60);
      item["avg_ScanTime1"] = item["avg_ScanTime"] + " sec";
      item["start_Date"] = moment(item["start_Scan"]).format("MM-DD-YYYY");
      item["start_Scan"] = moment(item["start_Scan"]).format("HH:mm:ss");

      item["end_Scan"] =
        item["end_Scan"] &&
        moment(item["end_Scan"]).format("MM-DD-YYYY HH:mm:ss A");
      return item;
    });
    return modifiedData;
  };

  // Store Summary Modify Data
  const modifyGroupSummaryData = (data) => {
    const convertSeconds = (seconds: number): string => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);

      return `${hours} h ${minutes} min`;
    };
    let modifiedData = data.map((item) => {
      item["total_Time"] = convertSeconds(item["total_Time"]);

      item["scan_Seconds"] = convertSeconds(item["scan_Seconds"]);

      return item;
    });
    return modifiedData;
  };

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };
  //#endregion

  //#region Filters Drawer

  //#endregion

  useEffect(() => {
    if (userContext && userContext.store && userContext.store !== 0) {
      getStoreName(userContext.store);
    }
  }, [userContext]);

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  const filterList = (value) => {
    if (value) {
      setAllSectionsFiltered(
        sectionsData.filter((item) =>
          item.description
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        )
      );
    } else {
      setAllSectionsFiltered(sectionsData);
    }
  };

  const filterListActive = (value) => {
    if (value) {
      setAllActivePlacementFiltered(
        ActivePlacementData.filter((item) =>
          item.upc.toLowerCase().includes(value.toString().toLowerCase())
        )
      );
    } else {
      setAllActivePlacementFiltered(ActivePlacementData);
    }
  };

  const filterListByConf = (value) => {
    if (value) {
      setAllPlacementByConfFiltered(
        placementByConfData.filter((item) =>
          item.upc.toLowerCase().includes(value.toString().toLowerCase())
        )
      );
    } else {
      setAllPlacementByConfFiltered(placementByConfData);
    }
  };

  const filterListStoreSummary = (value) => {
    if (value) {
      setAllStorePlacementSummaryFiltered(
        storePlacementSummaryData.filter((item) =>
          item.confirmation_ID
            .toLowerCase()
            .includes(value.toString().toLowerCase())
        )
      );

      setStorePlacementTotal(
        calculateTotals(
          storePlacementSummaryData.filter((item) =>
            item.confirmation_ID
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        )
      );
    } else {
      setStorePlacementTotal(calculateTotals(storePlacementSummaryData));

      setAllStorePlacementSummaryFiltered(storePlacementSummaryData);
    }
  };

  const getUniqueTable = (dataset, value) => {
    if (dataset.length > 0) {
      var uniqueDepartments = new Set(dataset.map((obj) => obj[value]));
      var uniqueDepartmentsString = [...uniqueDepartments].join(",");
      return uniqueDepartmentsString;
    }
  };

  const showFilteredTable = (value) => {
    switch (value) {
      case "1":
        return (
          <TableComponent
            isFromModal={false}
            tableDetails={ReportConstants["ActivePlacement"]}
            reportList={allActivePlacementFiltered}
            iconColumn={""}
            onTableRowClick={() => {}}
            reportType={""}
            totalTableRow={{}}
            isTotalRowRequired={false}
          />
        );
      case "2":
        if (allSectionsFiltered.length === 0) {
          return (
            <div style={{ fontSize: "20px", fontWeight: 700 }}>
              Good Work, No sections need to be scanned you're 100% caught up
              for this week!
            </div>
          );
        }
        return (
          <TableComponent
            isFromModal={false}
            tableDetails={ReportConstants["SectionsToScan"]}
            reportList={allSectionsFiltered}
            iconColumn={""}
            onTableRowClick={() => {}}
            reportType={""}
            totalTableRow={{}}
            isTotalRowRequired={false}
          />
        );
      case "3":
        return (
          <TableComponent
            isFromModal={false}
            tableDetails={ReportConstants["PlacementHistory"]}
            reportList={allHistoryFiltered}
            iconColumn={""}
            onTableRowClick={() => {}}
            reportType={""}
            totalTableRow={{}}
            isTotalRowRequired={false}
          />
        );

      case "4":
        return (
          <TableComponent
            isFromModal={false}
            tableDetails={ReportConstants["StorePlacementSummary"]}
            reportList={allStorePlacementSummaryFiltered}
            iconColumn={""}
            onTableRowClick={() => {}}
            reportType={""}
            totalTableRow={storePlacementTotal}
            isTotalRowRequired={true}
          />
        );
      case "5":
        return (
          <TableComponent
            isFromModal={false}
            tableDetails={ReportConstants["GroupPlacementSummary"]}
            reportList={allGroupPlacementSummaryFiltered}
            iconColumn={""}
            onTableRowClick={() => {}}
            reportType={""}
            totalTableRow={groupPlacementTotal}
            isTotalRowRequired={true}
          />
        );
      case "6":
        return (
          <>
            <div className="bg-container row m-0 mt-3 p-3">
              <div className="col-2 p-2 reportDescItem">
                <div className="label">
                  <p className="m-0">Confirmation ID</p>
                  <p className="m-0">
                    {getUniqueTable(
                      allPlacementByConfFiltered,
                      "confirmation_ID"
                    )}
                  </p>
                </div>
              </div>
              <div className="col-1 p-2 reportDescItem">
                <div className="label">
                  <p className="m-0">User</p>
                  <p className="m-0">
                    {getUniqueTable(allPlacementByConfFiltered, "user_ID")}
                  </p>
                </div>
              </div>
              <div className="col-2 p-2 reportDescItem">
                <div className="label">
                  <p className="m-0">Date</p>
                  <p className="m-0">
                    {getUniqueTable(allPlacementByConfFiltered, "date_Time")}
                  </p>
                </div>
              </div>
              <div className="col-1 p-2 reportDescItem">
                <div className="label">
                  <p className="m-0">Lines</p>
                  <p className="m-0">
                    {getUniqueTable(allPlacementByConfFiltered, "lines")}
                  </p>
                </div>
              </div>
              <div className="col-2 p-2 reportDescItem">
                <div className="label">
                  <p className="m-0">Received</p>
                  <p className="m-0">
                    {getUniqueTable(allPlacementByConfFiltered, "received")}
                  </p>
                </div>
              </div>
              <div className="col-2 p-2 reportDescItem">
                <div className="label">
                  <p className="m-0">Serial</p>
                  <p className="m-0">
                    {getUniqueTable(
                      allPlacementByConfFiltered,
                      "serial_Number"
                    )}
                  </p>
                </div>
              </div>

              <div className="col-2 p-2 reportDescItem">
                {selectedTab.value === "6" && (
                  <InputGroup
                    onChange={(event) => {
                      filterListByConf(event.target.value);
                    }}
                    style={{
                      width: 220,
                      height: 37,
                      fontWeight: 800,
                    }}
                  >
                    <Input placeholder="Filter UPC" />
                    <InputGroup.Button>
                      <SearchIcon />
                    </InputGroup.Button>
                  </InputGroup>
                )}
              </div>
            </div>
            <TableComponent
              isFromModal={false}
              tableDetails={ReportConstants["PlacementByConfID"]}
              reportList={allPlacementByConfFiltered}
              iconColumn={""}
              onTableRowClick={() => {}}
              reportType={""}
              totalTableRow={{}}
              isTotalRowRequired={false}
            />
          </>
        );
    }
  };

  return (
    <>
      <div className="scrollabe-div">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "5px",
          }}
        >
          {/* Tab Selection */}
          <InputPicker
            onChange={(e) =>
              setSelectedTab({ ...selectedTab, value: String(e) })
            }
            placeholder="Active Placement"
            size="md"
            data={data}
            cleanable={false}
            style={{ width: "220px", marginRight: "5px" }} // Simplified styles
            menuStyle={{ width: "220px" }}
          />

          {/* UPC Filter - Tab 1 */}
          {selectedTab.value === "1" && (
            <InputGroup style={{ maxWidth: "200px", marginLeft: "5px" }}>
              <Input placeholder="Filter UPC" />
              <InputGroup.Button>
                <SearchIcon />
              </InputGroup.Button>
            </InputGroup>
          )}

          {/* Description Filter - Tab 2 */}
          {selectedTab.value === "2" && (
            <InputGroup style={{ maxWidth: "200px" }}>
              <Input placeholder="Filter Description" />
              <InputGroup.Button>
                <SearchIcon />
              </InputGroup.Button>
            </InputGroup>
          )}

          {/* UPC and Execute Report - Tab 3 */}
          {selectedTab.value === "3" && (
            <>
              <div style={{ maxWidth: "200px", margin: "0 20px" }}>
                <InputGroup inside>
                  <Input onChange={(e) => setInputValue(e)} placeholder="UPC" />
                  <InputGroup.Addon>
                    <Whisper placement="top" speaker={<Tooltip>UPC</Tooltip>}>
                      <SearchIcon />
                    </Whisper>
                  </InputGroup.Addon>
                </InputGroup>
              </div>
              <Button onClick={executeReport} appearance="primary">
                Execute Report
              </Button>
            </>
          )}

          {/* Confirmation ID Filter - Tab 4 */}
          {selectedTab.value === "4" && (
            <InputGroup style={{ flex: "1", maxWidth: "200px" }}>
              <Input placeholder="Filter Confirmation ID" />
              <InputGroup.Button>
                <SearchIcon />
              </InputGroup.Button>
            </InputGroup>
          )}

          {/* Confirmation ID and Execute Report - Tab 6 */}
          {selectedTab.value === "6" && (
            <>
              <div style={{ maxWidth: "200px", margin: "0 20px" }}>
                <InputGroup inside>
                  <Input
                    onChange={(e) => setInputValueConfID(e)}
                    placeholder="Confirmation ID"
                  />
                  <InputGroup.Addon>
                    <Whisper
                      placement="top"
                      speaker={<Tooltip>ConfID</Tooltip>}
                    >
                      <SearchIcon />
                    </Whisper>
                  </InputGroup.Addon>
                </InputGroup>
              </div>
              <Button onClick={executeReportByConfID} appearance="primary">
                Execute Report
              </Button>
            </>
          )}

          <div style={{ marginBottom: "8px" }}>
            <PageHeaderComponent
              setGroupTypeID={setGroupTypeID}
              setAwgOrGotSelect={setAwgOrGotSelect}
              setUserStore={setUserStore}
              userContext={userContext}
              groupAndGroupID={groupAndGroupID}
              awgOrGot={awgOrGot}
              storeName={storeName}
              selectedDesc={selectedDesc}
              selectedID={selectedID}
              storeData={storeData}
              handleDescChange={handleDescChange}
              storeNameHeader={storeNameHeader}
              storeID={storeID}
              isVendor={false}
              isCalendar={
                selectedTab.value === "4" || selectedTab.value === "5"
              }
              isPlacementGroup={selectedTab.value === "5"}
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
            />
          </div>
        </div>

        <div>
          <div
            className="d-flex flex-nowrap justify-content-end align-items-center "
            style={{ marginBottom: "4px" }}
          >
            <Dropdown
              renderToggle={(_props, _ref) => (
                <div className="filter" ref={_ref} {..._props}>
                  <img
                    src={xlsxIcon}
                    alt="xls"
                    style={{
                      width: "30px",
                      height: "30px",
                      transition: "transform 0.3s",
                      cursor: "pointer",
                      fontSize: "30px", // Set the desired size here
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.1)")
                    } // Scale on hover
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    } // Reset scale on mouse leave
                    onClick={() => {
                      exportToExcel(
                        selectedTab.value === "1"
                          ? allActivePlacementFiltered
                          : selectedTab.value === "2"
                          ? allSectionsFiltered
                          : selectedTab.value === "3"
                          ? allHistoryFiltered
                          : selectedTab.value === "4"
                          ? allStorePlacementSummaryFiltered
                          : selectedTab.value === "5"
                          ? allGroupPlacementSummaryFiltered
                          : selectedTab.value === "6"
                          ? allPlacementByConfFiltered
                          : null,
                        `${
                          selectedTab.value === "1"
                            ? "Active Placement"
                            : selectedTab.value === "2"
                            ? "Sections to scan"
                            : selectedTab.value === "3"
                            ? "Placement History"
                            : selectedTab.value === "4"
                            ? "Store Summary"
                            : selectedTab.value === "5"
                            ? "Group Summary"
                            : selectedTab.value === "6"
                            ? "Placement by Confirmation#"
                            : null
                        } Report ${
                          selectedTab.value !== "5" ? "Store" : "Group"
                        }  ${
                          userContext && userContext.store
                            ? userContext.store
                            : selectedTab.value !== "5"
                            ? userStore
                            : groupTypeID
                        }`
                      );
                    }}
                  />
                </div>
              )}
              className="ms-2 me-0 button-fixed"
              appearance="link"
              placement="bottomEnd"
            ></Dropdown>
            {/*  <Dropdown
              renderToggle={(_props, _ref) => (
                <div className="filter" ref={_ref} {..._props}>
                  <Icon icon="pajamas:export" style={{ fontSize: "19px" }} />{" "}
                  Export
                </div>
              )}
              className="ms-2 me-0 button-fixed"
              appearance="link"
              placement="bottomEnd"
            >
              <Dropdown.Item
                icon={<FileDownloadIcon />}
                onClick={() => {
                  exportToExcel(
                    selectedTab.value === "1"
                      ? allActivePlacementFiltered
                      : selectedTab.value === "2"
                      ? allSectionsFiltered
                      : selectedTab.value === "3"
                      ? allHistoryFiltered
                      : selectedTab.value === "4"
                      ? allStorePlacementSummaryFiltered
                      : selectedTab.value === "5"
                      ? allGroupPlacementSummaryFiltered
                      : selectedTab.value === "6"
                      ? allPlacementByConfFiltered
                      : null,
                    `${
                      selectedTab.value === "1"
                        ? "Active Placement"
                        : selectedTab.value === "2"
                        ? "Sections to scan"
                        : selectedTab.value === "3"
                        ? "Placement History"
                        : selectedTab.value === "4"
                        ? "Store Summary"
                        : selectedTab.value === "5"
                        ? "Group Summary"
                        : selectedTab.value === "6"
                        ? "Placement by Confirmation#"
                        : null
                    } Report ${
                      selectedTab.value !== "5" ? "Store" : "Group"
                    }  ${
                      userContext && userContext.store
                        ? userContext.store
                        : selectedTab.value !== "5"
                        ? userStore
                        : groupTypeID
                    }`
                  );
                }}
              >
                Export to Excel
              </Dropdown.Item>
            </Dropdown> */}
          </div>
        </div>
        <div style={{ marginBottom: "50px" }}>
          {showFilteredTable(selectedTab.value)}
        </div>
      </div>
    </>
  );
};

export default ReportSectionsToScan;
