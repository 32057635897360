import { useState, useEffect, useRef } from "react";
import { SelectPicker, Input, Tabs, DatePicker, InputGroup } from "rsuite";
import Calendar from "./CalendarComponent";
import SearchIcon from "@rsuite/icons/Search";
import "./styles/_page-header.scss";
import ConfirmationListMain from "components/orders/ConfirmationList/ConfirmationListMain";
import { Reload } from "@rsuite/icons";
import { Button } from "rsuite";
//import ReplayIcon from '@mui/icons-material/Replay';

const PageHeaderComponent = (props: any) => {
  const {
    setGroupTypeID,
    setAwgOrGotSelect,
    setUserStore,
    userContext,
    groupAndGroupID,
    awgOrGot,
    storeName,
    storeNameHeader,
    storeID,
    groupDepartmentData,
    isDepartment,
    onOptionChangeDepartment,
    setToDate,
    setFromDate,
    fromDate,
    toDate,
    onOptionChangeVendor,
    allVendorName,
    isVendor,
    isCalendar,
    SelectMainOrGrind,
    mainOrGrind,
    isDashboard,
    isPlacementGroup,
    oldCalendar,
    storeData,
    selectedID,
    setSelectedID,
    selectedDesc,
    refreshAll,
    handleRefreshPage,
    handleDescChange,
  } = props;
  const containerRef = useRef(null);

  const [isGroupGrind, setIsGroupGrind] = useState(false);
  const [allConfData, setAllConfData] = useState([]);
  const [allConfDataFiltered, setAllConfDataFiltered] = useState([]);
  const [isSelectFilterType, setSetSelectFilterType] = useState(false);
  const SelectGroupType = (event) => {
    setGroupTypeID(event);
    setUserStore("");
    setIsGroupGrind(true);
  };

  const SelectAwgOrGot = (event) => {
    setAwgOrGotSelect(event);
  };

  const onOptionChangeHandler = (event) => {
    const VendorAndStore = event;
    setUserStore(VendorAndStore);
    setIsGroupGrind(false);
  };

  const calenderOnClick = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
  };

  const filterList = (value) => {
    var regex = /^[a-zA-Z]+$/;
    if (value) {
      if (!value.match(regex)) {
        setAllConfDataFiltered(
          allConfData.filter((item) =>
            item.got_Confirmation_ID
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      } else {
        setAllConfDataFiltered(
          allConfData.filter((item) =>
            item.user_ID.toLowerCase().includes(value.toString().toLowerCase())
          )
        );
      }
    } else {
      setAllConfDataFiltered(allConfData);
    }
  };
  

  return (
    <>
      {userContext && (
        <>
          <div
            className="page-headerl"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="page-header-items">
              {/* {isSelectFilterType && <ConfirmationListMain/>} */}

              <div ref={containerRef}>
                {isDashboard && (
                  <>
                    <SelectPicker
                      size="md"
                      placeholder={
                        userContext?.groupType === 3 ? "Grind" : "Dashboard"
                      }
                      onChange={SelectMainOrGrind}
                      data={mainOrGrind.map((item) => ({
                        label: item.label,
                        value: item.value,
                      }))}
                      style={{ width: "200px" }} // Set a fixed width for the dropdown button
                      menuStyle={{ width: "200px" }} // Set the same fixed width for the dropdown menu
                      placement="bottomStart" // Ensure dropdown opens downward
                    />
                  </>
                )}
              </div>

              {userContext && userContext.roleName === "Super Admin" ? (
                <>
                  <SelectPicker
                    placeholder="GOT"
                    onChange={SelectAwgOrGot}
                    label="Group Type"
                    data={awgOrGot.map((item) => ({
                      label: item.groupStore_desc,
                      value: item.groupStore_uid,
                    }))}
                    style={{ width: "260px" }} // Set a fixed width for the dropdown button
                    menuStyle={{ width: "260px" }} // Set the same fixed width for the dropdown menu
                    cleanable={false}
                  />

                  <SelectPicker
                    onChange={SelectGroupType}
                    label="Group"
                    virtualized
                    data={groupAndGroupID.map((item) => ({
                      label: item.groupStore_desc,
                      value: item.groupStore_uid,
                    }))}
                    style={{ width: "260px" }} // Set a fixed width for the dropdown button
                    menuStyle={{ width: "260px" }} // Set the same fixed width for the dropdown menu
                    cleanable={false}
                  />
                  {!isPlacementGroup ? (
                    <SelectPicker
                      onChange={onOptionChangeHandler}
                      label="Store"
                      placeholder={
                        storeName && !isDashboard && storeName[0]
                          ? "Select"
                          : "All"
                      }
                      // placeholder={selectedDesc}
                      virtualized
                      data={storeData?.map((item) => ({
                        label: `${item.id} - ${item.desc}`,
                        value: item.id,
                      }))}
                      style={{ width: "260px" }} // Set a fixed width for the dropdown button
                      menuStyle={{ width: "260px" }} // Set the same fixed width for the dropdown menu
                      cleanable={false}
                    />
                  ) : null}
                </>
              ) : userContext && userContext.store !== 0 ? (
                <>
                  <label
                    style={{ height: "30px", fontWeight: 700 }}
                    className=" alert"
                  >
                    Store: {storeNameHeader}
                  </label>{" "}
                </>
              ) : userContext &&
                userContext.groupTypeId &&
                userContext.groupTypeId !== "" ? (
                <>
                  {" "}
                  {!isPlacementGroup ? (
                    <SelectPicker
                      onChange={onOptionChangeHandler}
                      label="Store"
                      placeholder={
                        userContext && userContext.roleName === "DM"
                          ? storeName[0]
                          : storeName && !isDashboard && storeName[0]
                          ? storeName[0]
                          : "All"
                      }
                      data={storeData?.map((item) => ({
                        label: `${item.id} - ${item.desc}`,
                        value: item.id,
                      }))}
                      style={{ width: "260px" }} // Set a fixed width for the dropdown button
                      menuStyle={{ width: "260px" }} // Set the same fixed width for the dropdown menu
                      cleanable={false}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  <div ref={containerRef} style={{ position: "relative" }}>
                    {!(
                      userContext &&
                      userContext.groupType === 2 &&
                      userContext.groupTypeId === 0
                    ) && (
                      <SelectPicker
                        className="selectPicker"
                        container={() => containerRef.current}
                        onChange={SelectGroupType}
                        label="Group"
                        virtualized
                        data={groupAndGroupID.map((item) => ({
                          label: item.groupStore_desc,
                          value: item.groupStore_uid,
                        }))}
                        style={{ width: "260px" }} // Set a fixed width for the dropdown button
                        menuStyle={{ width: "260px" }} // Set the same fixed width for the dropdown menu
                        cleanable={false}
                      />
                    )}
                  </div>
                  {
                    <>
                      <div>
                        {!isPlacementGroup ? (
                          isGroupGrind ? (
                            <div>
                              <SelectPicker
                                onChange={(event) => {
                                  onOptionChangeHandler(event); // Existing method
                                  handleDescChange(event); // Also handle description change
                                }}
                                value={"All"}
                                label="Store"
                                placeholder={
                                  isGroupGrind
                                    ? "All"
                                    : storeName && storeName[0]
                                    ? storeName[0]
                                    : "All"
                                }
                                virtualized
                                data={storeData?.map((item) => ({
                                  label: `${item.id} - ${item.desc}`,
                                  value: item.id,
                                }))}
                                style={{ width: "260px" }} // Set a fixed width for the dropdown button
                                menuStyle={{ width: "260px" }} // Set the same fixed width for the dropdown menu
                                cleanable={false}
                              />
                            </div>
                          ) : (
                            <div
                              ref={containerRef}
                              style={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {/* Store Select */}
                              <SelectPicker
                                onChange={(event) => {
                                  onOptionChangeHandler(event); // Existing method
                                  handleDescChange(event); // Also handle description change
                                }}
                                value={selectedDesc}
                                container={() => containerRef.current}
                                label="Store"
                                placeholder="Select"
                                data={storeData?.map((item) => ({
                                  label: `${item.id} - ${item.desc}`,
                                  value: item.id,
                                }))}
                                style={{ width: "260px" }} // Set a fixed width for the dropdown button
                                menuStyle={{ width: "260px" }}
                                cleanable={false}
                              />
                            </div>
                          )
                        ) : null}
                      </div>
                    </>
                  }
                </>
              )}

              {isVendor && (
                <SelectPicker
                  onChange={onOptionChangeVendor}
                  label="Vendor"
                  virtualized
                  placeholder={
                    allVendorName && allVendorName[0]
                      ? /*  ? allVendorName[0]["vendor_Name"] */
                        "Select"
                      : "All"
                  }
                  data={allVendorName.map((item, index) => ({
                    label: item.vendor_Name,
                    value: item.vendor_ID,
                  }))}
                  style={{ width: "220px" }} // Set a fixed width for the dropdown button
                  menuStyle={{
                    width: "220px",
                  }} // Set the same fixed width for the dropdown menu
                  cleanable={false}
                />
              )}

              {isDepartment && (
                <SelectPicker
                  onChange={onOptionChangeDepartment}
                  label="Department"
                  placeholder="All"
                  virtualized
                  data={groupDepartmentData.map((item, index) => ({
                    label: item.department,
                    value: item.department,
                  }))}
                  style={{ width: "200px" }} // Set a fixed width for the dropdown button
                  menuStyle={{ width: "200px" }} // Set the same fixed width for the dropdown menu
                  cleanable={false}
                />
              )}
              {isCalendar && (
                <div
                  className="calendar-container"
                  style={{ marginBottom: 10, width: "260px" }}
                >
                  <div>
                    {oldCalendar ? (
                      <DatePicker
                        label="Report Date:"
                        oneTap
                        value={fromDate ? new Date(fromDate) : new Date()}
                        onChange={(value) => {
                          setFromDate(value);
                        }}
                        style={{ width: "260px" }} // Set a fixed width for the dropdown button
                        cleanable={false}
                        className="date-picker"
                      />
                    ) : (
                      <Calendar
                        style={{ width: "260px" }} // Set a fixed width for the dropdown button
                        value={[fromDate, toDate]}
                        calenderOnClick={calenderOnClick}
                        cleanable={false}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PageHeaderComponent;
