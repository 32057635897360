import { useEffect, useState, useRef } from "react";
import * as OrdersServices from "../../../../services/orderService";
import * as SettingsServices from "../../../../services/settingsServices";
import * as UMSService from "../../../../services/umsService";
import * as AuthService from "../../../../services/authService";
import Constants from "../../../.././utils/constants";
import Tooltip from "@mui/material/Tooltip";
import {
  Button,
  CheckPicker,
  Checkbox,
  Drawer,
  Dropdown,
  PickerHandle,
  Input,
  Modal,
  InputGroup,
  SelectPicker,
} from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { Icon } from "@iconify/react";
import PageHeaderComponent from "../../../common/pageHeaderComponent";
import FileDownloadIcon from "@rsuite/icons/FileDownload";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import { addDays } from "date-fns";
import TableComponent from "../ConfirmationListAll/TableComponentConf";
import { ConfirmationListConstants } from "./ConfirmationListConstants";
import ConfirmationListDetail from "../ConfirmationListAll/ConfirmationListDetail";
import xlsxIcon from "../../../../assets/images/xlsxIcon.png";

const ConfirmationListComponent = (props: any) => {
  const { userContext, handleSpinner, setIsGrindOrMain } = props;
  const [allConfData, setAllConfData] = useState([]);
  const [allConfDataFiltered, setAllConfDataFiltered] = useState([]);
  const [detailConf, setDetailConf] = useState("");
  const [storeID, setStoreID] = useState(userContext ? userContext.store : " ");
  const [storeName, setStoreName] = useState([]);
  const [storeData, setStoreData] = useState([]);
  const [selectedDesc, setSelectedDesc] = useState(null);
  const [selectedID, setSelectedID] = useState(null);
  const [confDetailData, setConfDetailData] = useState([]);
  const [filteredConfID, setFilteredConfID] = useState({});
  const [confDetailFilteredData, setConfDetailFilteredData] = useState([]);
  const [allConfDataFilteredByUser, setAllConfDataFilteredByUser] = useState(
    []
  );
  const [allVendorName, setAllVendorName] = useState([]);
  const [vendorID, setVendorID] = useState(allVendorName[1]);

  const handleDescChange = (value) => {
    setSelectedDesc(value);
    const selectedStore = storeData.find((item) => item.desc === value);
    if (selectedStore) {
      setSelectedID(selectedStore.id); // Set corresponding ID in the input box
    }
  };

  const [allConfListFiltered, setAllConfListFiltered] = useState([]);
  useEffect(() => {
    setAllConfListFiltered(confDetailData);
  }, [confDetailData]);
  const [fromDate, setFromDate] = useState<Date>(addDays(new Date(), 0));
  const [toDate, setToDate] = useState<Date>(addDays(new Date(), 0));
  //#region Filters Drawer
  const RenderDrawer = () => {
    return (
      <>
        <Drawer open={showFilter} onClose={() => setShowFilter(false)}>
          <Drawer.Header>
            <Drawer.Title>Filters</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <div className="content">
              <div className="row m-0">
                {columnFilters &&
                  columnFilters.length > 0 &&
                  columnFilters.map((result) => {
                    return (
                      <div className="col-12 p-2">
                        <label className="px-3 pb-1 w-100">
                          {result.label}
                        </label>
                        <CheckPicker
                          sticky
                          preventOverflow
                          className="w-100"
                          menuMaxHeight={190}
                          ref={picker}
                          cleanable={
                            selectedColumnFilters[result.key].length !==
                            filterCount[result.key].length
                          }
                          renderValue={(value, items) => {
                            const _value = [...value].sort((a, b) => {
                              let x = a ? a : "";
                              let y = b ? b : "";
                              return x
                                .toString()
                                .localeCompare(y.toString(), undefined, {
                                  numeric: true,
                                });
                            });
                            _value[0] === "" && _value.splice(0, 1, "(Empty)");
                            return (
                              <>
                                {_value.length < 3
                                  ? _value.splice(0, 2).join(", ")
                                  : _value[0] !== "(Empty)"
                                  ? _value.splice(0, 2).join(", ")
                                  : _value.splice(1, 2).join(", ")}{" "}
                                {_value.length > 0 ? (
                                  <span className="roundOff">
                                    {" "}
                                    {`+${_value.length}`}
                                  </span>
                                ) : (
                                  ``
                                )}
                              </>
                            );
                          }}
                          data={getCheckPickerData(result)}
                          style={{ width: 224 }}
                          value={selectedColumnFilters[result.key]}
                          placeholder={result.label}
                          renderMenuItem={(label, item) => {
                            return (
                              <div className="d-flex flex-column">
                                <i className="rs-icon rs-icon-user" /> {label}
                              </div>
                            );
                          }}
                          onChange={(value, event) => {
                            // console.log(value, event);
                            let selectedData = { ...selectedColumnFilters };
                            selectedData[result.key] = value;
                            setSelectedColumnFilters(selectedData);
                            filterSelected(filterCount, selectedData);
                          }}
                          renderExtraFooter={() => (
                            <div className="footer-styles">
                              <Checkbox
                                indeterminate={
                                  selectedColumnFilters[result.key].length >
                                    0 &&
                                  selectedColumnFilters[result.key].length <
                                    filterCount[result.key].length
                                }
                                checked={
                                  selectedColumnFilters[result.key].length ===
                                  filterCount[result.key].length
                                }
                                onChange={(value, checked) => {
                                  // let t0 = performance.now();
                                  let selectedData = {
                                    ...selectedColumnFilters,
                                  };
                                  selectedData[result.key] = checked
                                    ? filterCount[result.key]
                                    : [];
                                  setSelectedColumnFilters(selectedData);
                                  filterSelected(filterCount, selectedData);
                                  // let t1 = performance.now();
                                  // console.log("Onchange is taking " + (t1 - t0) + " milliseconds" );
                                }}
                              >
                                Select all
                              </Checkbox>
                            </div>
                          )}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="footer text-end">
              {isfilterSelected && (
                <Button
                  appearance="ghost"
                  className="mx-2"
                  onClick={() => {
                    clearFilter();
                  }}
                >
                  Reset Filter
                </Button>
              )}
              <Button
                appearance="primary"
                className="mx-2"
                disabled={
                  !isfilterSelected &&
                  allConfData &&
                  allConfData.length === reportMasterList.length
                }
                onClick={() => {
                  setShowFilter(false);
                  onFilterClick();
                }}
              >
                Apply Filter
              </Button>
            </div>
          </Drawer.Body>
        </Drawer>
      </>
    );
  };

  const getCheckPickerData = (result) => {
    let valueArr = [];
    if (result.data) {
      valueArr = result.data.map((res) => {
        return { label: res !== "" ? res : "(Empty)", value: res };
      });
    }
    return valueArr;
  };

  //#region ClearFilter
  const clearFilter = () => {
    setSelectedColumnFilters(filterCount);
    filterSelected(filterCount, filterCount);
    setAllConfData(reportMasterList);
    setAllConfDataFiltered(reportMasterList);
  };
  //#endregion
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const [totalAllData, setTotalAllData] = useState({});
  const [groupAndGroupID, setGroupAndGroupID] = useState([
    { groupStore_uid: "", groupStore_desc: "" },
  ]);
  const [storeNameHeader, setStoreNameHeader] = useState([]);

  const awgOrGot = [
    { groupStore_uid: "1", groupStore_desc: "GOT" },
    { groupStore_uid: "2", groupStore_desc: "AWG" },
  ];
  const [awgOrGotSelect, setAwgOrGotSelect] = useState(1);
  const [groupTypeID, setGroupTypeID] = useState(null);
  const [userStore, setUserStore] = useState("");
  useEffect(() => {
    if (
      userContext &&
      userContext.groupType === 2 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("2", "StoreType");

    if (
      userContext &&
      userContext.groupType === 1 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("1", "StoreType");

    if (
      userContext &&
      userContext.groupType === 3 &&
      userContext.groupTypeId === 0 &&
      userContext.roleId !== 3
    )
      GetFirstAllStore("3", "StoreType");
  }, [userContext]);

  useEffect(() => {
    if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (userContext && userContext.roleName === "Super Admin") {
      getVendorStoreName(groupTypeID);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId !== 0
    ) {
      getVendorStoreName(userContext.groupTypeId);
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0
    ) {
      getVendorStoreName(groupTypeID);
    }
  }, [groupTypeID, userContext]);

  useEffect(() => {
    if (
      userContext &&
      userContext.roleName &&
      userContext.roleName === "Super Admin"
    ) {
      GetGroupAndStoreByTypes(awgOrGotSelect, "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 1 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("1", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.groupTypeId === 0 &&
      userContext.groupType === 2 &&
      userContext.store === 0
    ) {
      GetGroupAndStoreByTypes("2", "GroupType");
    } else if (
      userContext &&
      userContext.groupType &&
      userContext.store !== 0
    ) {
      getStoreName(userContext.store);
      setUserStore(userContext.store);
    }
  }, [props.userContext, awgOrGotSelect]);

  useEffect(() => {
    getConfData(
      userStore,
      moment(fromDate).format("YYYY-MM-DD"),
      moment(toDate).format("YYYY-MM-DD")
    );
  }, [userStore, userContext, fromDate, toDate]);

  //#region Get Group and Store by Types
  const GetFirstAllStore = async (IDType, operation) => {
    userContext &&
      !userContext.store &&
      UMSService.GetGroupAndStoreByTypes(IDType, operation)
        .then((result) => {
          var setArrayName = [];
          var setArrayID = [];
          if (IDType === "2") {
            result.map((item) => {
              return (item.groupStore_desc =
                item.groupStore_uid + " / " + item.groupStore_desc);
            });
          }
          result.sort((a, b) => {
            const Store_A = a.groupStore_uid;
            const Store_B = b.groupStore_uid;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });

          result.filter((item) => {
            if (!setArrayID.includes(item.groupStore_uid)) {
              setArrayID.push(item.groupStore_uid);
            }
          });
          result.filter((item) => {
            if (!setArrayName.includes(item.groupStore_desc)) {
              setArrayName.push(item.groupStore_desc);
            }
          });

          const storeArray = result.map((item) => ({
            id: item.groupStore_uid,
            desc: item.groupStore_desc,
          }));
          if (storeArray.length > 0) {
            setSelectedDesc(storeArray[0].desc);
            setSelectedID(storeArray[0].id);
          }

          setStoreData(storeArray);
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error:  - ", err);
          // toast.error("Error Loading Orders");
        });
  };

  //#region Get Group and Store by Types
  const GetGroupAndStoreByTypes = async (IDType, operation) => {
    UMSService.GetGroupAndStoreByTypes(IDType, operation)
      .then((result) => {
        result.sort((a, b) => {
          const Store_A = a.groupStore_desc.toUpperCase();
          const Store_B = b.groupStore_desc.toUpperCase();

          if (Store_A < Store_B) {
            return -1;
          } else if (Store_A > Store_B) {
            return 1;
          } else {
            return 0;
          }
        });
        //console.log(result);
        setGroupAndGroupID(result);
      })
      .catch((err) => {
        console.error("Error:  - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //#region Vendor Store Name

  const getVendorStoreName = async (grouptypeID1 = "") => {
    const { handleSpinner, userContext = {} } = props;
    const { store, groupType, user_ID } = userContext;
    handleSpinner && handleSpinner(true);
    var storesArray = [];
    const uniqueVendor = new Set();

    AuthService.getDMStores(user_ID)
      .then((result1) => {
        result1.records.map((item) => storesArray.push(item.stores));
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
    debugger;
    if (store) {
      SettingsServices.getVendorStoreName(store, "Store")
        .then((result) => {
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }

          //console.log("StoreName: Result - ", result.records);
          var setArrayID = [];
          var setArrayName = [];

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          const storeArray = result.records.map((item) => ({
            id: item.store_ID,
            desc: item.store_Name,
          }));

          const uniquestores = [
            ...new Map(storeArray.map((item) => [item.id, item])).values(),
          ];

          setStoreData(uniquestores);
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    } else {
      var storesArray = [];
      AuthService.getDMStores(user_ID)
        .then((result1) => {
          result1.records.map((item) => storesArray.push(item.stores));
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        });
      SettingsServices.getVendorStoreName(grouptypeID1, "")
        .then((result) => {
          result.records.forEach((item) => {
            const { vendor_ID, vendor_Name } = item;
            uniqueVendor.add(JSON.stringify({ vendor_ID, vendor_Name }));
          });
          const uniqueVendorData = Array.from(uniqueVendor).map((store) =>
            JSON.parse(store as string)
          );
          setAllVendorName(uniqueVendorData);
          setVendorID(uniqueVendorData[0].vendor_ID.toString());
          result.records.sort((a, b) => {
            const Store_A = a.store_ID;
            const Store_B = b.store_ID;

            if (Store_A < Store_B) {
              return -1;
            } else if (Store_A > Store_B) {
              return 1;
            } else {
              return 0;
            }
          });
          if (storesArray.length >= 1) {
            result.records = result.records.filter((item) =>
              storesArray.includes(item.store_ID.toString())
            );
          }
          var setArrayID = [];
          var setArrayName = [];

          result.records.filter((item) => {
            if (!setArrayID.includes(item.store_ID)) {
              setArrayID.push(item.store_ID);
            }
          });
          result.records.filter((item) => {
            if (!setArrayName.includes(item.store_Name)) {
              setArrayName.push(item.store_Name);
            }
          });

          const storeArray = result.records.map((item) => ({
            id: item.store_ID,
            desc: item.store_Name,
          }));

          const uniquestores = [
            ...new Map(storeArray.map((item) => [item.id, item])).values(),
          ];

          setStoreData(uniquestores);
          setStoreName(setArrayName);
          setUserStore(setArrayID[0]);
          setStoreID(setArrayID);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const getStoreName = (storeID) => {
    SettingsServices.GetAdGroupandGroupID(storeID)
      .then((result) => {
        setStoreNameHeader(result.records[0].description);
      })
      .catch((err) => {
        console.error("Error: Get Data - ", err);
        // toast.error("Error Loading Orders");
      });
  };

  //#region Excel Export
  const exportToExcel = (excelData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: Constants.fileType });
    FileSaver.saveAs(data, fileName + Constants.fileExtension);
  };

  useEffect(() => {
    columnFiltersData();
  }, [allConfData]);

  function calculateTotals(data) {
    let totalitems = 0;
    let totalpieces = 0;

    for (let entry of data) {
      totalitems += Number(entry.items) || 0;
      totalpieces += Number(entry.quantity) || 0;
    }

    return {
      items: totalitems,
      quantity: totalpieces,
    };
  }

  const getConfData = async (store_ID, fromDate, toDate) => {
    const { handleSpinner, userContext = {} } = props;
    if (store_ID !== "") {
      store_ID = userContext.store ? userContext.store : store_ID;

      handleSpinner && handleSpinner(true);

      OrdersServices.GetOrdersConfirmationList(store_ID, fromDate, toDate)
        .then((result) => {
          result.confirmation_M.map((item) => {
            item.department = item.department.trim();
            item.vendor = item.vendor.trim();

            var momentDate = moment(item.delivery_Date, "M/D/YYYY h:mm:ss A");
            item.serial_Number = item.serial_Number.trim();
            var dayName = momentDate.format("dddd");
            if (item.delivery_Date.includes("12:00:00 AM")) {
              item.delivery_Date = item.delivery_Date.replace(
                "12:00:00 AM",
                ""
              );
            }
            item.day = dayName;
          });
          //console.log(result.confirmation_M);
          setTotalAllData(calculateTotals(result.confirmation_M));
          setAllConfData(result.confirmation_M);
          setReportMasterList(result.confirmation_M);
          setAllConfDataFiltered(result.confirmation_M);
        })
        .catch((err) => {
          console.error("Error: Get Data - ", err);
          // toast.error("Error Loading Orders");
        })
        .finally(() => {
          handleSpinner && handleSpinner(false);
        });
    }
  };

  const filterList = (value) => {
    var regex = /^[a-zA-Z]+$/;
    if (value) {
      if (!value.match(regex)) {
        setAllConfDataFiltered(
          allConfData.filter((item) =>
            item.got_Confirmation_ID
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      } else {
        setAllConfDataFiltered(
          allConfData.filter((item) =>
            item.user_ID.toLowerCase().includes(value.toString().toLowerCase())
          )
        );
      }
    } else {
      setAllConfDataFiltered(allConfData);
    }
  };
  const columnFiltersData = () => {
    let filtersArr = [];
    ConfirmationListConstants["ConfirmationListConstants"].map((value) => {
      value.isSearch &&
        filtersArr.push({
          key: value.DataKey,
          label: value.Header,
          data: [
            ...new Set(reportMasterList.map((_) => _[value.DataKey])),
          ].sort((a, b) => {
            let x = a ? a : "";
            let y = b ? b : "";
            return x
              .toString()
              .localeCompare(y.toString(), undefined, { numeric: true });
          }),
        });
    });
    columnFiltersOnSelect();
    setColumnFilters(filtersArr);
    // console.log(filtersArr);
  };

  const columnFiltersOnSelect = () => {
    let selectedFiltersArr = {};
    ConfirmationListConstants["ConfirmationListConstants"].map((value) => {
      if (value.isSearch) {
        selectedFiltersArr[value.DataKey] = [
          ...new Set(reportMasterList.map((_) => _[value.DataKey])),
        ];
      }
    });
    setFilterCount(selectedFiltersArr);
    setSelectedColumnFilters(selectedFiltersArr);
    filterSelected(selectedFiltersArr, selectedFiltersArr);
  };

  const filterSelected = (filterCount, selectedData) => {
    let isSelected = false;
    for (const data in filterCount) {
      if (
        filterCount[data] &&
        selectedData[data] &&
        filterCount[data].length !== selectedData[data].length
      )
        isSelected = true;
    }
    setFilterSelected(isSelected);
  };

  const [columnFilters, setColumnFilters] = useState([]);
  const [selectedColumnFilters, setSelectedColumnFilters] = useState({});
  const [filterCount, setFilterCount] = useState({});
  const [isfilterSelected, setFilterSelected] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [reportMasterList, setReportMasterList] = useState([]);
  const picker = useRef<PickerHandle>();

  //#region Panel Filter Onclick
  const onFilterClick = () => {
    let data = reportMasterList;
    data = data.filter((result) => {
      let selected = true;
      for (let prop in selectedColumnFilters) {
        if (selected) {
          selected =
            selectedColumnFilters[prop] &&
            selectedColumnFilters[prop].length > 0
              ? selectedColumnFilters[prop].some(
                  (item) => item.toString() === result[prop].toString()
                )
              : true;
        }
      }
      return selected;
    });
    setAllConfData(data);
    setAllConfDataFiltered(data);
  };

  // const filterListDetail = (value) => {
  //   if (value) {
  //     setAllConfDataFilteredByUser(
  //       confDetailFilteredData.filter((item) =>
  //         item.upc.toLowerCase().includes(value.toString().toLowerCase())
  //       )
  //     );
  //   } else {
  //     setAllConfDataFilteredByUser(confDetailFilteredData);
  //   }
  // };

  const filterListDetail = (value) => {
    var regex = /^[a-zA-Z]+$/;
    if (value) {
      if (!value.match(regex)) {
        setAllConfDataFilteredByUser(
          confDetailFilteredData.filter((item) =>
            item.upc
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      } else {
        setAllConfDataFilteredByUser(
          confDetailFilteredData.filter((item) =>
            item.description
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      }
    } else {
      setAllConfDataFilteredByUser(confDetailFilteredData);
    }
  };

  const filterListConfirmationDetail = (value) => {
    var regex = /^[a-zA-Z]+$/;
    if (value) {
      if (!value.match(regex)) {
        setAllConfListFiltered(
          confDetailData.filter((item) =>
            item.upc
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      } else {
        setAllConfListFiltered(
          confDetailData.filter((item) =>
            item.description
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
        );
      }
    } else {
      setAllConfListFiltered(confDetailData);
    }
  };

  const handleRowClick = (rowIndex) => {
    var newSelectedRows = [...selectedData];

    if (
      newSelectedRows.some(
        (item) =>
          item.got_Confirmation_ID === allConfData[rowIndex].got_Confirmation_ID
      )
    ) {
      newSelectedRows = newSelectedRows.filter(
        (item) =>
          item.got_Confirmation_ID !== allConfData[rowIndex].got_Confirmation_ID
      );
    } else {
      newSelectedRows = newSelectedRows.concat(allConfData[rowIndex]);
    }

    //setSelectedDataTotal(calculateTotals(newSelectedRows));
    //State'i güncelleyin

    setFilteredConfID(
      newSelectedRows.map((item) => {
        return item.got_Confirmation_ID;
      })
    );
    if (newSelectedRows.length > 0) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }

    setSelectedData(newSelectedRows);
  };

  const getUniqueTable = (dataset, value) => {
    if (dataset.length > 0) {
      var uniqueDepartments = new Set(dataset.map((obj) => obj[value]));
      var uniqueDepartmentsString = [...uniqueDepartments].join(",");
      return uniqueDepartmentsString;
    }
  };

  function setCurrentPage(arg0: number): void {
    throw new Error("Function not implemented.");
  }
  let isDesktop;
  if (window.innerWidth > 1080) {
    isDesktop = true;
  }

  const mainOrGrind = [
    { value: "1", label: "Main" },
    { value: "2", label: "Department" },
  ];
  const SelectMainOrGrind = (event) => {
    setIsGrindOrMain(event);
  };
  return (
    <>
      {/* work */}
      <div className="OrderInvoice" style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <SelectPicker
            placement="bottomStart"
            size="md"
            className="header-bar-item"
            placeholder="Select Filter Type"
            onChange={SelectMainOrGrind}
            data={mainOrGrind.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
            style={{ width: "180px", marginRight: "-10px" }} // Set consistent margin for right
            menuStyle={{ width: "180px", marginRight: "0px" }}
          />

          {/* Add additional SelectPickers here in the same way */}

          <PageHeaderComponent
            setGroupTypeID={setGroupTypeID}
            setAwgOrGotSelect={setAwgOrGotSelect}
            setUserStore={setUserStore}
            userContext={userContext}
            groupAndGroupID={groupAndGroupID}
            awgOrGot={awgOrGot}
            storeName={storeName}
            selectedDesc={selectedDesc}
            selectedID={selectedID}
            storeData={storeData}
            handleDescChange={handleDescChange}
            storeNameHeader={storeNameHeader}
            storeID={storeID}
            isVendor={false}
            isCalendar={true}
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
          {isFiltered ? (
            <div className="col-1 p-0 mx-3 reportDescItem">
              <div className="label" style={{ marginLeft: "-12px" }}>
                <Button
                  onClick={() => {
                    selectedData && setOpen1(true);
                  }}
                  appearance="primary"
                  className="m-0"
                >
                  Filter
                </Button>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          {/* work */}
          <div className="mt-1 last-div">
            {/* First Row */}
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="left-content">
                {/* Content for the first row */}
              </div>
            </div>

            {/* Second Row - Aligned to the right */}
            <div
              className="d-flex justify-content-end align-items-center w-100 mt-3"
              style={{ marginBottom: "10px" }}
            >
              <div
                className="mx-2 d-flex align-items-center filter"
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
                style={{ cursor: "pointer" }}
              >
                <Icon
                  icon="material-symbols:filter-alt-outline-sharp"
                  style={{ fontSize: "30px" }}
                />
                <span className="ms-1">Filters</span>
              </div>

              {/* work */}
              <Dropdown
                renderToggle={(_props, _ref) => (
                  <div
                    className="filter d-flex align-items-center"
                    ref={_ref}
                    {..._props}
                  >
                    <Tooltip title="Export to Excel" arrow>
                      <img
                        src={xlsxIcon}
                        alt="xls"
                        style={{
                          width: "30px",
                          height: "30px",
                          transition: "transform 0.3s",
                          cursor: "pointer",
                          fontSize: "30px", // Set the desired size here
                          marginLeft: "-7px",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = "scale(1.1)")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                        onClick={() => {
                          exportToExcel(
                            allConfDataFiltered,
                            `View Confirmations ${userStore}`
                          );
                        }}
                      />
                    </Tooltip>
                  </div>
                )}
                style={{ marginTop: "0px" }}
                className="ms-3"
                appearance="link"
                placement="bottomEnd"
              />
            </div>
          </div>
        </div>
      </div>

      {RenderDrawer()}
      <TableComponent
        userContext={userContext}
        isFromModal={false}
        tableDetails={ConfirmationListConstants["ConfirmationListConstants"]}
        reportList={allConfDataFiltered ? allConfDataFiltered : allConfData}
        iconColumn={"vendor"}
        onTableRowClick={(e) => {
          setDetailConf(e);
        }}
        setOpen={setOpen}
        reportType={""}
        setSelectedData={setSelectedData}
        handleRowClick={handleRowClick}
        selectedData={selectedData}
        setSelectedRow={setSelectedRow}
        totalTableRow={totalAllData}
        isTotalRowRequired={true}
      />

      <Modal size={"full"} open={open} onClose={handleClose}>
        <div className="scrollable-div">
          <Modal.Header>
            <div className="modal-header">
              <Modal.Title>
                <span
                  className="mx-2 filter"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Icon icon="ep:back" />
                </span>
                <div style={{ textAlign: "center" }}>
                  Confirmation List Detail
                </div>
              </Modal.Title>
            </div>
            <div className="bg-container row m-0 mt-3 p-3">
              <div className="col-12 col-md-2 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Store ID</p>
                  <p className="m-0">{userStore}</p>
                </div>
              </div>

              <div className="col-12 col-md-2 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">User ID</p>
                  <p className="m-0">
                    {getUniqueTable(confDetailData, "user_ID")}
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-2 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Department</p>
                  <p className="m-0">
                    {getUniqueTable(confDetailData, "department")}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-2 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Conf ID</p>
                  <p className="m-0">
                    {getUniqueTable(allConfListFiltered, "confirmation_ID")}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-2 p-2 reportDescItem">
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Serial Number</p>
                  <p className="m-0">
                    {getUniqueTable(allConfListFiltered, "serial_Number")}
                  </p>
                </div>
              </div>

              <div className="col-12 col-md-3 p-2 reportDescItem">
                <div className="label">
                  <InputGroup
                    onChange={(event) => {
                      filterListConfirmationDetail(event.target.value);
                    }}
                    style={{
                      height: 40,
                      marginTop: "10px",
                      fontWeight: 800,
                    }}
                  >
                    <Input placeholder="Filter Description or UPC" />
                    <InputGroup.Button>
                      <Icon icon="mdi:magnify" />
                    </InputGroup.Button>
                  </InputGroup>
                </div>
              </div>
              <div className="col-12 col-md-1 p-2 reportDescItem">
                <div className="label">
                  <p className="m-0">
                    <Dropdown
                      renderToggle={(_props, _ref) => (
                        <div className="filter" ref={_ref} {..._props}>
                          <Icon icon="pajamas:export" /> Export
                        </div>
                      )}
                      className="ms-2 me-0 button-fixed"
                      appearance="link"
                      placement="bottomEnd"
                    >
                      <Dropdown.Item
                        icon={<Icon icon="mdi:file-download-outline" />}
                        onClick={() => {
                          exportToExcel(
                            allConfListFiltered,
                            `Confirmation List Detail`
                          );
                        }}
                      >
                        Export to Excel
                      </Dropdown.Item>
                    </Dropdown>
                  </p>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <ConfirmationListDetail
              handleSpinner={handleSpinner}
              setConfDetailData={setConfDetailData}
              confDetailData={allConfListFiltered}
              userContext={userContext}
              ConfID={detailConf}
              tableDetails={
                ConfirmationListConstants["ConfirmationListDetailConstants"]
              }
              store_ID={userStore}
            />
          </Modal.Body>
        </div>
      </Modal>

      <Modal size={"full"} open={open1} onClose={handleClose1}>
        <Modal.Header>
          <div>
            <Modal.Title>
              <span
                className="mx-2 filter"
                onClick={() => {
                  setOpen1(false);
                }}
              >
                <Icon icon="ep:back" />
              </span>
              <div style={{ textAlign: "center" }}>Selected Data Detail</div>
            </Modal.Title>
          </div>
          <div className="bg-container d-flex justify-content-between">
            <div className="col-1 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:store-outline" />
                </div>
                <div className="label">
                  <p className="m-0">Store ID</p>
                  <p className="m-0">{userStore}</p>
                </div>
              </>
            </div>
            <div className="col-1 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">User ID</p>
                  <p className="m-0">
                    {/* {confDetailData &&
                      confDetailData[0] &&
                      confDetailData[0].user_ID} */}
                    {getUniqueTable(
                      allConfDataFilteredByUser
                        ? allConfDataFilteredByUser
                        : allConfDataFiltered,
                      "user_ID"
                    )}
                  </p>
                </div>
              </>
            </div>

            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Department</p>
                  <p className="m-0">
                    {/* {confDetailData &&
                      confDetailData[0] &&
                      confDetailData[0].department} */}
                    {getUniqueTable(
                      allConfDataFilteredByUser
                        ? allConfDataFilteredByUser
                        : allConfDataFiltered,
                      "department"
                    )}
                  </p>
                </div>
              </>
            </div>
            <div className="col-3 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Conf ID</p>
                  <p className="m-0">
                    {/* {confDetailData &&
                      confDetailData[0] &&
                      confDetailData[0].confirmation_ID} */}
                    {getUniqueTable(
                      allConfDataFilteredByUser
                        ? allConfDataFilteredByUser
                        : allConfDataFiltered,
                      "confirmation_ID"
                    )}
                  </p>
                </div>
              </>
            </div>

            <div className="col-2 p-2 reportDescItem">
              <>
                <div className="icon">
                  <Icon icon="material-symbols:person-outline-rounded" />
                </div>
                <div className="label">
                  <p className="m-0">Serial Number</p>
                  <p className="m-0">
                    {/* {confDetailData &&
                      confDetailData[0] &&
                      confDetailData[0].department} */}
                    {getUniqueTable(
                      allConfDataFilteredByUser
                        ? allConfDataFilteredByUser
                        : allConfDataFiltered,
                      "serial_Number"
                    )}
                  </p>
                </div>
              </>
            </div>

            <div className="col-3 p-2 reportDescItem">
              <>
                <div className="label">
                  <InputGroup
                    onChange={(event) => {
                      filterListDetail(event.target.value);
                    }}
                    style={{
                      width: 300,
                      height: 40,
                      marginTop: "10px",
                      fontWeight: 800,
                    }}
                  >
                    <Input placeholder="Filter Description or UPC" />
                    <InputGroup.Button>
                      <SearchIcon />
                    </InputGroup.Button>
                  </InputGroup>
                </div>
              </>
            </div>
          </div>
          <div className="col-1 p-2 reportDescItem">
            <>
              <div className="label">
                <p className="m-0">
                  {
                    <Dropdown
                      renderToggle={(_props, _ref) => (
                        <div className="filter" ref={_ref} {..._props}>
                          <Icon icon="pajamas:export" /> Export
                        </div>
                      )}
                      className="ms-2 me-0 button-fixed"
                      appearance="link"
                      placement="bottomEnd"
                    >
                      <Dropdown.Item
                        icon={<FileDownloadIcon />}
                        onClick={() => {
                          exportToExcel(
                            allConfDataFilteredByUser
                              ? allConfDataFilteredByUser
                              : allConfDataFiltered,
                            `Confirmation Filtered  Detail`
                          );
                        }}
                      >
                        Export to Excel
                      </Dropdown.Item>
                    </Dropdown>
                  }
                </p>
              </div>
            </>
          </div>
        </Modal.Header>
        <Modal.Body>
          <ConfirmationListDetail
            handleSpinner={handleSpinner}
            setConfDetailData={setConfDetailFilteredData}
            setAllConfDataFilteredByUser={setAllConfDataFilteredByUser}
            confDetailData={
              allConfDataFilteredByUser
                ? allConfDataFilteredByUser
                : confDetailFilteredData
            }
            tableDetails={
              ConfirmationListConstants[
                "ConfirmationListDetailFilteredConstants"
              ]
            }
            userContext={userContext}
            ConfID={filteredConfID}
            store_ID={userStore}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ConfirmationListComponent;
