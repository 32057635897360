import { Navigate, Outlet, useLocation } from "react-router-dom";
import RoutingConstants from "../../utils/routingConstants";
import { StorageService } from "../../utils/sessionService";

export const ProtectedRoute = ({
  isAllowed,
  redirectPath = RoutingConstants.signinCallback,
  children,
}) => {
  let location = useLocation();
  let context = StorageService.getSLSItem("UserContext", false);

  const redirectToOffice = () => {
    //return (window.location.href = "https://office.gotsystems.net");
  };

  if (!context) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }

  if (context.groupType === 2 && context.roleName !== "Super Admin") {
    redirectToOffice();
  }
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
