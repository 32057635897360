export const OrderListConstants = [
  /* {
    Header: "Mark",
    DataKey: "mark1",
    Width: 50,
    isFixed: true,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  }, */
  {
    Header: "Store",
    DataKey: "storeID",
    Width: 30,
    isFixed: true,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Type",
    DataKey: "fileType",
    Width: 50,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Status",
    DataKey: "status",
    Width: 120,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Delivery",
    DataKey: "deliveryDate",
    Width: 70,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Day",
    DataKey: "deliveryDay",
    Width: 30,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
 /*  {
    Header: "Moved",
    DataKey: "movedLate",
    Width: 30,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Late",
    DataKey: "movedLate",
    Width: 30,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  }, */
  {
    Header: "Deadline",
    DataKey: "deadLineDateTime",
    Width: 80,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Conf#",
    DataKey: "confirmationID",
    Width: 80,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Vendor",
    DataKey: "vendorID",
    Width: 70,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Number",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Department",
    DataKey: "departmentStoreID",
    Width: 50,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Number",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Inv",
    DataKey: "invoiceType",
    Width: 30,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Number",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Brkr",
    DataKey: "breakerID",
    Width: 30,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Number",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Lines",
    DataKey: "linesnew",
    Width: 30,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Number",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Pieces",
    DataKey: "piecesNew",
    Width: 30,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Number",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "AG Conf#",
    DataKey: "agConfirmationID",
    Width: 80,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Received",
    DataKey: "dateTimeIn",
    Width: 80,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Serial",
    DataKey: "serialNumber",
    Width: 100,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Blind",
    DataKey: "blindOrder",
    Width: 30,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "User",
    DataKey: "userID",
    Width: 30,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Who Release",
    DataKey: "whoRelease",
    Width: 100,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "Sent",
    DataKey: "sentAcc",
    Width: 30,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
  {
    Header: "ACC",
    DataKey: "sentAcc",
    Width: 30,
    isFixed: false,
    isSortable: false,
    Align: "left",
    TypeOfData: "Text",
    isSearch: true,
    isHeader: true,
    Icon: "",
    isSubHeader: false,
    colSpan: 1,
  },
];
