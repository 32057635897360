import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useMediaQuery } from "usehooks-ts";

const MainChart = (props: any) => {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const {
    labels,
    value,
    totalValue,
    toDate,
    fromDate,
    MainData,
    setDepToStore,
    setStoreToDep,
    scrollTo,
    ref1,
    setFirstLayer,
    setSecondLayer,
    setThirdLayer,
  } = props;

  const numberValue = value.map((value) => Number(value));
  function calculatePercentages(numbers) {
    const total = numbers.reduce((sum, number) => sum + number, 0);
    const percentages = numbers.map((number) =>
      ((number / total) * 100).toFixed(2)
    );
    return percentages;
  }
  const newPercentages = calculatePercentages(numberValue);

  const showRightDataNoPerSign = value.map((value) => parseFloat(value));
  if (totalValue !== null || totalValue !== undefined) {
    var TotalValueRight = parseFloat(totalValue).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
  const combineObject = labels.map((label, index) => ({
    label,
    value: showRightDataNoPerSign[index],
  }));

  combineObject.sort((a, b) => b.value - a.value);

  const newSortLabels = [];
  const newSortValues = [];

  combineObject.map((object) => {
    return newSortLabels.push(object.label);
  });

  combineObject.map((object) => {
    return newSortValues.push(
      object.value.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
    );
  });

  const options = {
    borderWidth: 0,
    hoverBorderWith: 1,
    borderRadius: 3,
    maintainAspectRatio: useMediaQuery("(max-width: 768px)") ? false : true,

    plugins: {
      datalabels: {
        display: false,
        color: "#575757;",
        font: { weight: 900, size: 10 },
      },
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: false,
        text: `From ${fromDate} To ${toDate} Track IT Shrink $ Price`,
      },

      tooltip: {
        callbacks: {
          label: (ttItem) => `${ttItem.parsed}%`,
        },
        displayColors: false,
      },
    },
  };

  //Change labels "_" to " "
  /*   const SortLabels = newSortLabels.map((label) => {
    if (label.includes("_")) {
      return label.replaceAll("_", " ");
    }
    return label;
  }); */
  const charTextColor = [
    "rgba(255, 154, 158, 1)",
    "rgba(213, 166, 189, 1)",
    "rgba(177, 91, 140, 1)",
    "rgba(131, 67, 129, 1)",
    "rgba(81, 47, 113, 1)",
    "rgba(247, 183, 163, 1)",
    "rgba(255, 241, 201, 1)",
  ];
  const data = {
    labels: labels,
    datasets: [
      {
        data: newPercentages,
        backgroundColor: charTextColor,
        borderColor: charTextColor,
        spacing: 5,
        hoverOffset: 2,
      },
    ],
  };

  return (
    <>
      <div className="center-chart">
        <Doughnut options={options} data={data} />
      </div>
      <div className="details-chart">
        <ul>
          {newSortLabels.map((label, index) => {
            return (
              <li className="chart-li">
                <div className="input-color">
                  <div
                    className="color-box"
                    style={{ backgroundColor: charTextColor[index] }}
                  ></div>
                </div>
                <div
                  style={{
                    color: "black",
                    fontWeight: "400",
                    filter: "contrast(300)",
                  }}
                  className="chart-label"
                  // onClick={(e) => {
                  //   scrollTo(ref1);
                  //   if (e.currentTarget.innerHTML.includes(" ")) {
                  //     MainData(e.currentTarget.innerHTML.replaceAll(" ", "_"));
                  //     scrollTo(ref1);

                  //     setDepToStore(true);
                  //     setStoreToDep(true);
                  //   } else {
                  //     MainData(e.currentTarget.innerHTML);
                  //     scrollTo(ref1);
                  //     setDepToStore(true);
                  //     setStoreToDep(true);
                  //   }
                  //   scrollTo(ref1);
                  //   setFirstLayer(false);
                  //   setSecondLayer(true);
                  //   setDepToStore(true);
                  //   setStoreToDep(true);
                  // }}
                >
                  {label.includes("_") ? label.replaceAll("_", " ") : label}

                  {": "}
                </div>
                <span className="percantage">{" " + newSortValues[index]}</span>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="chart-li-item">
        Total:
        <span className="percantage">{TotalValueRight}</span>
      </div>
    </>
  );
};

export default MainChart;
